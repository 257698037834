import axios from "axios";

import { setAuthUser } from "../redux/actions/Auth";
// const dev = true
export const api = process.env.REACT_APP_KEY_URLAPI;
// const api = 'https://research.econ.cmu.ac.th/econ-api/'
export default axios.create({
  baseURL: api, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});
const getAuthState = () => {
  return {
    userToken: localStorage.getItem("token"),
  };
};
export const getAuthUser = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const getHttp = (path, dispatch, token = getAuthState().userToken) => {
  let _axios = axios.create({
    baseURL: api, //YOUR_API_URL HERE
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    timeout: 600000,
  });
  return _axios
    .get(path)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.status === false && res.data.isExpired === true) {
          localStorage.removeItem("token");
          if (dispatch !== undefined) {
            dispatch(setAuthUser(null));
          }
        }
        return { status: true, data: res.data };
      } else {
        return { status: false };
      }
    })
    .catch(() => {
      return { status: false };
    });
};

export const postHttp = (path, data, token = getAuthState().userToken) => {
  let _axios = axios.create({
    baseURL: api, //YOUR_API_URL HERE
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    timeout: 600000,
  });
  return _axios
    .post(path, data)
    .then((res) => {
      if (res.status === 200) {
        return { status: true, data: res.data };
      } else {
        return { status: false };
      }
    })
    .catch((error) => {
      return { status: false };
    });
};
