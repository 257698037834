import React, { useContext, useEffect } from "react";
import App from "components";
import { Box, Grid } from "@material-ui/core";
import List from "../../components/list";
const pangNameInfo = "air-quality-info";
const breadCrumbs = {
  primary: "Quick Reply (Rich Menu air Quality)",
  secondary:
    "ตั้งค่า Quick Reply ที่ปรากฏ เมื่อทำการกด Rich Menu : air Quality",
};
const tableName = "airQuality";
const Index = () => {
  const dispatch = App.Redux.useDispatch();
  const { setAlertDialog } = useContext(App.AppContext);
  const [list, setList] = React.useState(null);
  const setup = List.setup(
    setList,
    pangNameInfo,
    tableName,
    dispatch,
    setAlertDialog,
  );
  useEffect(() => {
    setup.getData();
  }, []);
  return (
    <App.PageContainer heading={""}>
      <Grid
        container
        style={{
          padding: 20,
          paddingBottom: 40,
        }}
        spacing={2}
      >
        <List.Head
          {...{
            pangNameInfo,
            breadCrumbs,
          }}
        />
        <Grid sm={12}>
          <App.MaterialReactTableUC
            columns={setup.Columns}
            list={list || []}
            pagesize={true}
            onDragEnd={setup.onDragEnd}
          />
        </Grid>
      </Grid>
      <Box
        style={{
          position: "absolute",
          bottom: "10px",
          color: "red",
          fontSize: "12px",
        }}
      >
        {
          "หมายเหตุ : ข้อมูล Quick Reply จะถูกนำไปแสดงใน Line Official Account จำนวน 10 รายการเท่านั้น"
        }
      </Box>
    </App.PageContainer>
  );
};
export default Index;
