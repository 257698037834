import liff from "@line/liff";
import {
    Box, Avatar, Button, Typography, TextField, Grid, LinearProgress,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
// import App from "components";
import DateFnsUtils from '@date-io/date-fns';
import { Fragment, useEffect, useState } from "react";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { postHttp } from "services/http";
import provinces from "./provinces";
import amphoes from "./amphoes";
import Dialog from '@material-ui/core/Dialog';
import Swal from "sweetalert2";
// const liffId = process.env.REACT_APP_KEY_LIFFID || "4e0ecf85f4f9bc9a11a5b717ea0dce30"
const liffId = "2007142861-j9Mvbxer"



const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "100%"
        }
    }
}))
// const newsOptions = [
//     { label: "Facebook", value: "facebook" },
//     { label: "Line OA", value: "line_oa" },
//     { label: "Website ของมหาวิทยาลัย", value: "university_website" },
//     { label: "อื่น ๆ", value: "other" }
// ];
const RegisterPage = () => {

    const classes = useStyles()
    const [lineProfile, setLineProfile] = useState(null)
    const [isSave, setIsSave] = useState(false)
    const [formData, setFormData] = useState({
        isRegister: null,
        first_name: "",
        last_name: "",
        email: "",
        amphoes: null,
        provinces: null,
        phone: "",
        gender: "",
        birthdate: new Date(),
        news_source: "",
        pdpa_acceptance: false
    })
    const LineLogin = async () => {
        await liff.init(
            { liffId: liffId },
            () => {
                if (liff.isLoggedIn()) {
                    liff
                        .getProfile()
                        .then(async (profile) => {
                            console.log(profile.userId)
                            console.log(profile.pictureUrl)
                            console.log(profile.displayName)
                            setLineProfile(profile)
                        })
                        .catch((err) => console.error(err));
                } else {
                    liff.login();
                }
            },
            (err) => console.error(err)
        );
    }
    const checkRegister = async () => {
        console.log("lineProfile", lineProfile)
        let _checkRegister = await postHttp("/register/checkRegister", lineProfile);
        console.log("checkRegister", _checkRegister.data)
        if (_checkRegister.data.status) {
            setFormData(_checkRegister.data)
        }
    }
    const Register = async (e) => {
        e.preventDefault();
        const _data = { ...formData, ...lineProfile }
        console.log("_data", _data)
        setIsSave(true)
        let _Register = await postHttp("/register/Register", _data);
        await checkRegister()
        setIsSave(false)
        Swal.fire({
            icon: "success"
        });
    }
    useEffect(() => {
        LineLogin()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (lineProfile) {
            checkRegister()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineProfile]);

    return <Box style={{
        width: "100vw", height: "100vh",
        // border: "1px solid red",
        padding: "20px 40px"

    }}
        className={classes.root}>
        <Box>
            <Box component={"form"}

                onSubmit={Register}
            >
                <Grid
                    container

                    spacing={2}
                >
                    <Grid item xs={12} style={{
                        display: "flex", alignItems: "center", justifyContent: "center",
                    }}>
                        <Typography variant="h1" gutterBottom>
                            ฟอร์มลงเบียนเข้าใช้งานไลน์ AirCMU
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{
                        gap: 10, alignItems: "center", justifyContent: "center",
                        width: "100%",
                        display: "flex"
                    }}>
                        <Box>
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <Avatar src={lineProfile?.pictureUrl} />
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="h1" gutterBottom>{lineProfile?.displayName}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {formData.isRegister === null || isSave === true &&
                        <Grid item xs={12}>
                            <LinearProgress color="primary" />
                        </Grid>}


                    {formData.isRegister !== null &&
                        <Fragment>
                            <Grid item xs={12}>
                                <TextField id="first_name" label="ชื่อ" variant="outlined"
                                    onChange={((event) => setFormData((e) => ({ ...e, [event.target.id]: event.target.value })))}
                                    maxlength={50}
                                    required
                                    disabled={formData.isRegister || isSave}
                                    value={formData.first_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="last_name" label="นามสกุล" variant="outlined"
                                    onChange={((event) => setFormData((e) => ({ ...e, [event.target.id]: event.target.value })))}
                                    maxlength={50}
                                    required
                                    disabled={formData.isRegister || isSave}
                                    value={formData.last_name} />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    disablePortal
                                    id="gender"

                                    options={[{ label: "ชาย", value: "ชาย" },
                                    { label: "หญิง", value: "หญิง" },
                                    { label: "ไม่ระบุ", value: "ไม่ระบุ" }
                                    ]}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => setFormData((e) => ({
                                        ...e,
                                        "gender": value?.value || "",
                                    }))}
                                    value={{
                                        label: formData.gender,
                                        value: formData.gender
                                    }}
                                    required
                                    disabled={formData.isRegister || isSave}
                                    renderInput={(params) => <TextField {...params} label="เพศ"
                                        variant="outlined"
                                        required
                                    />}
                                />
                                {/* <TextField label="เพศ" id="gender" variant="outlined"
                                    disabled={formData.isRegister || isSave}
                                    value={formData.gender} onChange={e => setFormData({ ...formData, gender: e.target.value })} required /></Grid> */}
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker format="dd/MM/yyyy"
                                        margin="normal"
                                        id="birthdate" label="วันเกิด"

                                        value={formData?.birthdate}
                                        onChange={(date, v) => {
                                            console.log(v)
                                            console.log(formData)
                                            setFormData({ ...formData, birthdate: date })

                                        }
                                        }
                                        disabled={formData.isRegister || isSave}
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        inputVariant="outlined"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    label="อีเมล์"
                                    variant="outlined"
                                    type="email"
                                    onChange={(event) => setFormData((e) => ({ ...e, [event.target.id]: event.target.value }))}
                                    // required
                                    placeholder="กรอกอีเมล์"
                                    inputProps={{
                                        title: "กรุณากรอกอีเมล์ในรูปแบบที่ถูกต้อง (เช่น example@domain.com)"
                                    }}
                                    disabled={formData.isRegister || isSave}
                                    value={formData.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="phone"
                                    type="tel"
                                    label="หมายเลขโทรศัพท์"
                                    variant="outlined"
                                    onChange={(event) => setFormData((prevState) => ({ ...prevState, [event.target.id]: event.target.value }))}
                                    pattern="^0[89]{1}[0-9]{8}$"
                                    placeholder="กรอกหมายเลขโทรศัพท์"
                                    // required
                                    inputProps={{
                                        pattern: "^0[89]{1}[0-9]{8}$", // กำหนด pattern ให้กับ input เพื่อการตรวจสอบ
                                        title: "กรุณากรอกหมายเลขโทรศัพท์ในรูปแบบที่ถูกต้อง (เช่น 0812345678)"
                                    }}
                                    value={formData.phone}
                                    disabled={formData.isRegister || isSave}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6">พักอาศัยในพื้นที่</Typography>
                            </Grid>
                            <Grid item xs={12}>

                                <Autocomplete
                                    disablePortal
                                    id="provinces"

                                    options={provinces}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => setFormData((e) => ({
                                        ...e,
                                        "provinces": value?.value || "",
                                        amphoes: ""
                                    }))}
                                    value={{
                                        label: provinces.find(e => e.value == formData.provinces)?.label,
                                        value: provinces.find(e => e.value == formData.provinces)?.value
                                    }}
                                    required
                                    disabled={formData.isRegister || isSave}
                                    renderInput={(params) => <TextField {...params} label="จังหวัด"
                                        variant="outlined"
                                        required

                                    />}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <Autocomplete
                                    disablePortal
                                    id="amphoes"

                                    options={amphoes.filter(e => e.P == formData.provinces)}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => setFormData((e) => ({ ...e, "amphoes": value?.value || "" }))}
                                    value={{ label: amphoes.find(e => e.value == formData.amphoes)?.label, value: amphoes.find(e => e.value == formData.amphoes)?.value }}

                                    disabled={(formData.provinces == null || formData.provinces == "") || formData.isRegister || isSave}
                                    renderInput={(params) => <TextField {...params} label="อำเภอ"
                                        variant="outlined"
                                        required
                                    />}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <Typography variant="h6">ช่องทางที่ได้รับคำแนะนำข้อมูล Line OA "AirCMU" จากหน่วยงาน (ระบุ)</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="news_source"
                                    type="text"

                                    variant="outlined"
                                    onChange={(event) => setFormData((prevState) => ({ ...prevState, [event.target.id]: event.target.value }))}

                                    placeholder={"หน่วยงาน"}
                                    required

                                    value={formData.news_source}
                                    disabled={formData.isRegister || isSave}
                                />
                            </Grid>


                            <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                <FormControlLabel control={<Checkbox checked={formData.pdpa_acceptance}
                                    disabled={formData.isRegister || isSave}
                                    onChange={e => setFormData({ ...formData, pdpa_acceptance: e.target.checked })} />}
                                    label="ข้าพเจ้ายินยอมให้ทางหน่วยงานเก็บรวบรวม และใช้ข้อมูลส่วนบุคคล สำหรับการใช้งาน Line OA “AirCMU”" />
                            </Grid>
                            <Grid item xs={12} style={{ justifyContent: "center", display: "flex" }}>
                                <Button type="submit" variant="contained" color="primary"
                                    disabled={formData.isRegister || isSave || !formData.pdpa_acceptance}
                                >ส่งข้อมูล</Button>
                            </Grid>
                        </Fragment>}
                </Grid>
            </Box>
        </Box>
    </Box >
}

export default RegisterPage

/*
1. พักอาศัยในพื้นที่
   - อำเภอ
   - จังหวัด 
2. เพศ
3. วันเกิด
4. ช่องทางที่รับทราบข่าวสาร 
   - Facebook
   - Line OA
   - Website ของมหาวิทยาลัย
   - อื่น ๆ (ระบุ)
5. การยอมรับเงื่อนไขการเก็บข้อมูล PDPA
*/