import React, { Fragment, useContext } from "react";
import {
  Grid,
  Box,
  FormControl,
  TextField,
  CardMedia,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FileUploader } from "react-drag-drop-files";
import { makeStyles } from "@material-ui/styles";
import App from "components";
const useStyles = makeStyles((theme) => ({
  Radio: {
    "& .MuiTypography-root ": { fontSize: "14px" },
  },
  location: {
    "& p": {
      color: "#000",
    },
  },
}));
const type = [
  {
    value: "system",
    label: "System",
  },
  {
    value: "message",
    label: "Message",
  },
  {
    value: "image",
    label: "Image",
  },
  {
    value: "uri",
    label: "URL Link",
  },
  {
    value: "postback",
    label: "Carousel",
  },
];
const General = ({ data, onChange, classes }) => {
  const Styles = useStyles();
  const { setAlertDialog } = useContext(App.AppContext);
  return (
    <Fragment>
      <Grid container item {...{ xs: 12 }} spacing={2}>
        <Grid item {...{ xs: 12 }}>
          <Typography variant="inherit">
            ไอคอน (.png) <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item {...{ xs: 12 }}>
          <Box
            style={{
              width: "100px",
              height: "100px",
            }}
          >
            <FileUploader
              multple={false}
              maxSize={4}
              handleChange={(e, v) => {
                // console.log("result", e)
                if (e.type.toLowerCase().includes("png")) {
                  const _URL = window.URL || window.webkitURL;
                  const img = new Image();
                  const objectUrl = _URL.createObjectURL(e);
                  const size = e.size / 1024 / 1024;
                  img.onload = function () {
                    if (this.width != 50 || this.height != 50) {
                      setAlertDialog({
                        open: true,
                        type: "alert",
                        msg: "ขนาดภาพไม่ถูกต้อง ขนาดภาพแนะนำ 50 * 50 px กรุณาอัปโหลดภาพใหม่ที่มีขนาดเท่ากับที่แนะนำ",
                        Close: (even) => {
                          even();
                        },
                      });
                    } else {
                      if (size > 1) {
                        setAlertDialog({
                          open: true,
                          type: "alert",
                          msg: "ขนาดไฟล์: สูงสุด 1 MB",
                          Close: (even) => {
                            even();
                          },
                        });
                      } else {
                        App.service.getBase64(e, (result) => {
                          console.log("result", result);
                          onChange({
                            name: "image_path",
                            value: result,
                          });
                        });
                      }
                    }

                    _URL.revokeObjectURL(objectUrl);
                  };
                  img.src = objectUrl;
                } else {
                  setAlertDialog({
                    open: true,
                    type: "alert",
                    msg: "ประเภทไฟล์ไม่ถูกต้อง ไฟล์ที่รองรับ: .PNG",
                    Close: (even) => {
                      even();
                    },
                  });
                }
              }}
              // fileOrFiles={data.image.text}
              name="image"
              type={["JPGE", "PNG"]}
            >
              <Box
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1px ${App.service.isNullOrEmpty(data?.image_path) ? "dashed" : "solid"} ${!App.service.isNullOrEmpty(data?.error?.image_path) ? "#f44336" : "#e3e3e3"}`,
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                {!App.service.isNullOrEmpty(data?.image_path) ? (
                  <CardMedia
                    style={{
                      width: "50px",
                      height: "50px",
                      // border: "1px solid red"
                    }}
                    image={data?.image_path}
                  ></CardMedia>
                ) : (
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M14.8225 12.6307C15.1129 12.3411 15.4999 12.1686 15.9095 12.1465C16.3191 12.1244 16.7225 12.2541 17.0425 12.5107L17.1771 12.6307L20.9425 16.3907C21.1842 16.6301 21.3252 16.9528 21.3367 17.2928C21.3483 17.6328 21.2294 17.9643 21.0045 18.2195C20.7796 18.4747 20.4657 18.6344 20.127 18.6657C19.7883 18.697 19.4504 18.5977 19.1825 18.3881L19.0571 18.2774L17.3331 16.5547V28.0001C17.3328 28.3399 17.2026 28.6668 16.9693 28.9139C16.7361 29.161 16.4172 29.3097 16.078 29.3296C15.7387 29.3496 15.4046 29.2392 15.144 29.0211C14.8834 28.8029 14.7159 28.4935 14.6758 28.1561L14.6665 28.0001V16.5547L12.9425 18.2774C12.7029 18.5186 12.3801 18.6592 12.0403 18.6703C11.7005 18.6815 11.3693 18.5624 11.1144 18.3375C10.8594 18.1125 10.7001 17.7987 10.6689 17.4601C10.6377 17.1215 10.737 16.7839 10.9465 16.5161L11.0571 16.3894L14.8225 12.6307ZM15.3331 2.66675C19.0451 2.66675 22.2131 5.00008 23.4478 8.28275C25.2925 8.78651 26.8958 9.93438 27.967 11.5184C29.0383 13.1024 29.5068 15.0177 29.2876 16.9174C29.0683 18.817 28.1759 20.5753 26.7719 21.8736C25.368 23.1719 23.5454 23.9244 21.6345 23.9947L21.3331 24.0001H19.9998V21.3334C20.7688 21.3335 21.5216 21.1119 22.1679 20.6952C22.8142 20.2784 23.3267 19.6842 23.644 18.9837C23.9612 18.2831 24.0698 17.506 23.9568 16.7453C23.8437 15.9847 23.5138 15.2727 23.0065 14.6947L22.8265 14.5027L19.0611 10.7441C18.2809 9.96538 17.2333 9.51329 16.1314 9.47985C15.0296 9.4464 13.9565 9.83413 13.1305 10.5641L12.9385 10.7441L9.17314 14.5041C8.63023 15.0465 8.2548 15.7336 8.09162 16.4834C7.92845 17.2333 7.98444 18.0143 8.25289 18.7332C8.52135 19.4521 8.99094 20.0786 9.60567 20.538C10.2204 20.9974 10.9543 21.2703 11.7198 21.3241L11.9998 21.3334V24.0001H9.33314C7.80076 24.0011 6.31481 23.4741 5.1254 22.508C3.93599 21.5418 3.11574 20.1954 2.8027 18.6953C2.48967 17.1952 2.70296 15.6331 3.40668 14.2719C4.1104 12.9106 5.26159 11.8334 6.66648 11.2214C6.69593 8.94234 7.62201 6.7666 9.24415 5.16543C10.8663 3.56426 13.0539 2.66656 15.3331 2.66675Z"
                          fill={
                            !App.service.isNullOrEmpty(data?.error?.image_path)
                              ? "#f44336"
                              : "#B8B9BA"
                          }
                        />
                      </svg>
                    </Box>
                    <Box
                      style={{
                        fontSize: "12px",
                        color: !App.service.isNullOrEmpty(
                          data?.error?.image_path,
                        )
                          ? "#f44336"
                          : "#B8B9BA",
                      }}
                    >
                      เลือกไอคอน
                    </Box>
                  </Box>
                )}
              </Box>
            </FileUploader>
          </Box>
        </Grid>
        <Grid
          item
          {...{ xs: 12 }}
          style={{
            color: !App.service.isNullOrEmpty(data?.error?.image_path)
              ? "#f44336"
              : "#B8B9BA",
          }}
        >
          <Typography variant="caption">
            {data?.error?.image_path ||
              "(ขนาดไอคอน : 50 px * 50 px นามสกุลไฟล์ png ไฟล์ไม่เกิน 1MB)"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item {...{ xs: 12 }}>
        <Grid
          item
          {...{ xs: 12 }}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="inherit">
            ข้อความ <span style={{ color: "red" }}>*</span>
          </Typography>
          <Typography
            variant="caption"
            style={{
              color: (data?.name || "").length == 20 ? "#f16666ba" : "#e3e3e3",
            }}
          >
            {`${(data?.name || "").length}/20`}
          </Typography>
        </Grid>
        <Grid item {...{ xs: 12 }}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{}}
          >
            <TextField
              id="name"
              name="name"
              disabled={data.type == "system" && data.type_line == "location"}
              type={"text"}
              onChange={(props) => onChange(props.target)}
              value={data?.name || ""}
              variant="outlined"
              inputProps={{
                maxLength: 20,
              }}
              placeholder="ข้อความ"
              error={data?.error?.name}
              helperText={data?.error?.name}
              className={Styles.location}
              // helperText={"เลือกตำแหน่ง"}
            />
          </FormControl>
          {data.type == "system" && data.type_line == "location" && (
            <Box
              component={"p"}
              style={{ color: "#b8b9ba", fontSize: "12px", fontWeight: "300" }}
            >
              ไม่สามารถแก้ไขข้อความได้ เนื่องจากใช้สำหรับตรวจสอบการดึงข้อมูลจาก
              API
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container item {...{ xs: 12 }} spacing={1}>
        <Grid item {...{ xs: 12 }}>
          <Typography variant="inherit">
            ลำดับ <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item {...{ xs: 12 }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              id="list_no"
              name="list_no"
              type={"text"}
              onChange={(props) => onChange(props.target)}
              value={parseInt(data?.list_no || "0") + 1}
              variant="outlined"
              inputProps={{
                maxLength: 1,
              }}
              placeholder="ลำดับ"
              error={""}
              helperText={""}
              disabled={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item {...{ xs: 12 }}>
        <Grid item {...{ xs: 12 }}>
          <Typography variant="inherit">
            การแสดง <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item {...{ xs: 12 }}>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={(data?.is_display || "").toString()}
            defaultValue={(data?.is_display || "false").toString()}
            // disabled={data.type == "system"}
            className={Styles.Radio}
            onChange={(e, v) => {
              onChange({
                name: "is_display",
                value: v,
              });
            }}
          >
            <FormControlLabel
              value={"true"}
              control={
                <Radio
                  color="primary"
                  // disabled={data.type == "system"}
                  size="small"
                />
              }
              label="แสดง"
              labelPlacement="end"
            />
            <FormControlLabel
              value={"false"}
              control={
                <Radio
                  color="primary"
                  // disabled={data.type == "system"}
                  size="small"
                />
              }
              label="ไม่แสดง"
              labelPlacement="end"
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid container item {...{ xs: 12 }}>
        <Grid item {...{ xs: 12 }}>
          <Typography variant="inherit">
            ประเภท <span style={{ color: "red" }}>*</span>
          </Typography>
        </Grid>
        <Grid item {...{ xs: 12 }}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              id="type"
              value={data.type}
              name="type"
              onChange={(props) => onChange(props.target)}
              style={{
                width: "100%",
              }}
              disabled={data.type == "system"}
            >
              {type.map((e, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={e.value}
                    disabled={e.value == "system"}
                  >
                    {e.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default General;
