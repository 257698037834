import React, { useContext, useImperativeHandle } from "react";
import IconButton from "@material-ui/core/IconButton";

import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import LayoutContext from "../LayoutContext";

const contentRef = React.createRef();

const SidebarToggleHandler = React.forwardRef(
  function SidebarToggleHandler(props, ref) {
    const { isSidebarOpen, setSidebarOpen } = useContext(LayoutContext);

    useImperativeHandle(ref, () => ({
      isOpen: isSidebarOpen,
    }));

    return (
      <IconButton
        className="Cmt-toggle-menu"
        ref={contentRef}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
        {...props}
      >
        <MenuOpenIcon style={{ color: "#000" }} />
      </IconButton>
    );
  },
);

export default SidebarToggleHandler;
