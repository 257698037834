import React, { useState } from "react";
import IntlMessages from "../../../utils/IntlMessages";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Button,
  CardMedia,
  // , Typography
} from "@material-ui/core";
// import { AuhMethods } from '../../../../services/auth'
import ContentLoader from "../../ContentLoader";
import { alpha, makeStyles } from "@material-ui/core/styles";

import { CurrentAuthMethod } from "../../../constants/AppConstants";
import { NavLink } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import { postHttp } from "../../../../services/http";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as fn from "../../../../services/default.service";

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    padding: "0 50px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
      order: 1,
    },
    [theme.breakpoints.up("xl")]: {
      // padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 35,
    marginTop: 35,
    color: theme.palette.text.primary,
    fontWeight: "bold !important",
    fontSize: "18px !important",
    "&.MuiTypography-root": {
      fontSize: "18px !important",
      color: "#000",
    },
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(15px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -7px) scale(.75)",
    },
    "& .MuiFormLabel-root": { fontSize: "14px" },
    "& input": {
      padding: "0 15px",
      height: "40px",
    },
    marginBottom: "0px",
    marginTop: "0px",
  },
  textFieldRootError: {
    "& fieldset": {
      borderColor: "red !important",
    },
    "& .MuiInputLabel-root": {
      color: "red  !important",
    },
  },
  formcontrolLabelRoot: {
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      color: "#000",
    },
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const Resetpassword = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
}) => {
  const { Id } = useParams();
  let history = useHistory();
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const [showPassword, setshowPassword] = React.useState(false);

  const [invalid, setInvalid] = useState({
    newPassword: {
      invalid: false,
      msg: "",
    },
    confirmPassword: {
      invalid: false,
      msg: "",
    },
  });
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const valid = () => {
    let isvalid = !0;
    if (fn.IsNullOrEmpty(newPassword)) {
      isvalid = !1;
      setInvalid((pre) => ({
        ...pre,
        newPassword: { msg: "กรุณาระบุ New Password", invalid: !0 },
      }));
    }
    if (fn.IsNullOrEmpty(confirmPassword)) {
      isvalid = !1;
      setInvalid((pre) => ({
        ...pre,
        confirmPassword: { msg: "กรุณาระบุ  Confirm Password", invalid: !0 },
      }));
    }
    if (!fn.IsNullOrEmpty(newPassword) && !fn.IsNullOrEmpty(confirmPassword)) {
      if (newPassword !== confirmPassword) {
        isvalid = !1;
        setInvalid((pre) => ({
          ...pre,
          newPassword: { msg: "", invalid: !1 },
        }));
        setInvalid((pre) => ({
          ...pre,
          confirmPassword: { msg: "Confirm Password ไม่ถูกต้อง", invalid: !0 },
        }));
      }
    }

    return isvalid;
  };
  const onSubmit = async () => {
    if (valid()) {
      dispatch(fetchStart());
      let isLogin = await postHttp("users/resetpassword", {
        Id,
        password: newPassword,
      });
      dispatch(fetchSuccess());
      if (isLogin.status) {
        if (isLogin.data.status) {
          history.push("/signin");
        } else {
        }
      } else {
        dispatch(fetchError("error 400 "));
      }
    }
  };
  const onChange = (e) => {
    if (e.target.name === "newPassword") {
      setInvalid((pre) => ({ ...pre, newPassword: { msg: "", invalid: !1 } }));
      setnewPassword(e.target.value);
    } else {
      setInvalid((pre) => ({
        ...pre,
        confirmPassword: { msg: "", invalid: !1 },
      }));
      setconfirmPassword(e.target.value);
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CardMedia src={"/images/auth/login-img.png"} />
        </Box>
      ) : null}
      <div className={classes.authContent}>
        <Box
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "26px",
          }}
        >
          <img
            src={"/images/logomini.png"}
            style={{ height: "50px" }}
            alt="logo-econ"
          />
        </Box>
        <Box component="div" variant="h1" className={classes.titleRoot}>
          RESET PASSWORD
        </Box>
        <form>
          <Box style={{ height: "40", marginBottom: "25px" }}>
            <TextField
              type={showPassword ? "text" : "password"}
              name="newPassword"
              label={"New Password"}
              fullWidth
              onChange={onChange}
              Value={newPassword}
              margin="normal"
              variant="outlined"
              className={
                classes.textFieldRoot +
                " " +
                (invalid.newPassword.invalid ? classes.textFieldRootError : "")
              }
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setshowPassword(!showPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              style={{
                padding: "0 0 0 10px",
                height: "18px",
                fontSize: "12px",
                color: invalid.newPassword.invalid ? "red" : "transparent",
                position: "absolute",
              }}
            >
              {invalid.newPassword.msg}
            </Box>
          </Box>
          <Box style={{ height: "40", marginBottom: "25px" }}>
            <TextField
              type={showPassword ? "text" : "password"}
              name="confirmPassword"
              label={"Confirm Password"}
              fullWidth
              onChange={onChange}
              Value={confirmPassword}
              margin="normal"
              variant="outlined"
              className={
                classes.textFieldRoot +
                " " +
                (invalid.confirmPassword.invalid
                  ? classes.textFieldRootError
                  : "")
              }
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setshowPassword(!showPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              style={{
                padding: "0 0 0 10px",
                height: "18px",
                fontSize: "12px",
                color: invalid.confirmPassword.invalid ? "red" : "transparent",
                position: "absolute",
              }}
            >
              {invalid.confirmPassword.msg}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="end" mb={5}>
            <Box component="p" fontSize={{ xs: 14, sm: 14 }}>
              <NavLink to="/signin" style={{ color: "#9E76B4" }}>
                <IntlMessages id="appModule.goLogin" />
              </NavLink>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              style={{ width: "100%", height: "50px" }}
            >
              Reset
            </Button>
          </Box>
        </form>

        {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default Resetpassword;
