import App from "components";
import columns from "./columns";
const setUp = (setList, pangNameInfo, tableName, dispatch, setAlertDialog) => {
  const getData = async () => {
    dispatch(App.Redux.fetchStart());
    const res = await App.service.getHttp(`/quickReply/${tableName}`, dispatch);
    dispatch(App.Redux.fetchSuccess());
    if (res.status) {
      setList([...(res?.data || [])]);
    }
  };
  const doDelete = async (Id) => {
    dispatch(App.Redux.fetchStart());
    const res = await App.service.getHttp("/quickReply/delete/" + Id);
    // dispatch(App.Redux.fetchSuccess())
    getData();
  };
  const onDragEnd = async (list) => {
    dispatch(App.Redux.fetchStart());
    const res = await App.service.postHttp("/quickReply/dragEnd", list);
    // dispatch(App.Redux.fetchSuccess())
    getData();
  };

  const Columns = columns({
    info: pangNameInfo,
    doDelete,
    getData,
    setAlertDialog,
  });
  return {
    getData,
    doDelete,
    onDragEnd,
    Columns,
  };
};
export default setUp;
