import React from "react";
import * as fn from "../services/default.service";
import MaterialTable from "material-table";
// import MRT_TableContainer from 'material-react-table'
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// import DehazeIcon from '@mui/icons-material/Dehaze';
const useStyles = makeStyles((theme) => ({
  IsPageSizeNull: {
    "& .MuiTableFooter-root .MuiTableCell-footer": {
      border: "none",
    },
    "& .MuiTablePagination-toolbar": {
      display: "none",
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root.MuiTableCell-head": {
      backgroundColor: "#e5e5e5",
      height: "50px !important",
      padding: "0 10px",
      "& .Mui-TableHeadCell-Content": {
        display: "flex",
        alignItems: "center",
        height: "100%",
      },
    },
    "& .MuiPaper-root": {
      boxShadow: "none !important",
      border: "1px solid #E3E3E3",
    },
    "& th:first-child .Mui-TableHeadCell-Content-Wrapper": {
      display: "none",
    },
  },
}));

export const PatchedPagination = (props) => {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
};
export const MaterialTableUC = ({ columns, list, tableRef, pagesize }) => {
  const classes = useStyles();

  let options = {
    toolbar: false,
    draggable: true,
    // pageSize: 10,
    // pageSizeOptions: [{ value: _pageSize, label: 'All' }],
  };
  if (pagesize === true) {
    options = {
      toolbar: false,
      draggable: true,
      pageSize: 10,
      // pageSizeOptions: [{ value: _pageSize, label: 'All' }],
    };
  }
  return (
    <div className={pagesize === true ? "" : classes.IsPageSizeNull}>
      <MaterialTable
        tableRef={tableRef}
        icons={fn.tableIcons}
        columns={columns}
        data={list}
        options={options}
        // onPageChange={() => { }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <h1
                style={{
                  // height: '300px',
                  textAlign: "center",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></h1>
            ),
          },
        }}
        onColumnDragged={() => {}}
        components={{
          Pagination: PatchedPagination,
        }}
      />
    </div>
  );
};
export const MaterialReactTableUC = ({ columns, list, onDragEnd }) => {
  const classes = useStyles1();

  const table = useMaterialReactTable({
    columns,
    data: list,
    enableRowOrdering: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSorting: false,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    muiRowDragHandleProps: ({ row }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          list.splice(
            hoveredRow.index,
            0,
            list.splice(draggingRow.index, 1)[0],
          );
          let item = [];
          list.map((e, i) => {
            item.push({
              ...e,
              id: e.id,
              list_no: i,
            });
          });
          console.log("list", item);
          onDragEnd(item);
        }
      },
      // disabled: row.original.type == "system",
    }),

    // enableRowSelection: true, //table options as options to this hook
  });
  return (
    <Box className={classes.root}>
      <MaterialReactTable
        table={table}

        // icons={{
        //   DragHandleIcon: () => <DehazeIcon />
        // }}
      />
    </Box>
  );
};

export default MaterialTableUC;
