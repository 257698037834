import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import service from "undefined-service-web";

import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import { Grid, Button, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { fetchStart, fetchSuccess } from "../../../redux/actions";
import AppContext from "../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";

import { FileUploader } from "react-drag-drop-files";

import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
  search: {
    "& .MuiInputBase-root,input": {
      height: "30px",
      borderRadius: "20px",
      fontSize: "14px",
    },
    width: "100%",
    maxWidth: "600px",
  },
  FileUploader: {
    "& .dropfile": {
      width: "100%",
      border: "1px solid " + theme.palette.custom.main + " !important",
      borderRadius: "10px",
      cursor: "pointer",
    },
    "& .dropfile.error": {
      border: "1px solid red !important",
      "& .dropfile-body": {
        border: "1px dashed red !important",
      },
    },
    "& .dropfile-body": {
      border: "1px dashed  " + theme.palette.custom.main + " ",
      width: "100%",
      height: "100%",
      borderRadius: "10px",
    },
    "& .dropfile-body.image": {
      border: "none",
    },
  },
}));
const PM25UC = ({ name, index, previewRealtime, previewDaily }) => {
  const classes = useStyles();
  const _realtime = previewRealtime.find((e) => e.i == index);
  const _daily = previewDaily.find((e) => e.i == index);

  return (
    <Box style={{ display: "flex" }} mb={1.5}>
      <Box className={classes.lbl} style={{ width: "120px" }}>
        {name}
      </Box>
      <Box
        className={classes.lbl}
        style={{
          width: "100px",
          color: service.isNullOrEmpty(_realtime?.data?.level)
            ? "#000"
            : _realtime?.data?.level,
        }}
      >
        {service.isNullOrEmpty(_realtime?.data?.pm25)
          ? "-"
          : _realtime?.data?.pm25}
      </Box>
      <Box
        className={classes.lbl}
        style={{
          // width: '100px',
          color: service.isNullOrEmpty(_daily?.level) ? "#000" : _daily?.level,
        }}
      >
        {service.isNullOrEmpty(_daily?.pm25) ? "-" : _daily?.pm25}
      </Box>
    </Box>
  );
};
const list = [
  [18.805300989134, 98.95522782797465], //เชียงใหม่
  [19.51939747001329, 100.2960931356148], //น่าน
  [16.433410362822176, 102.82383817502875], //ขอนแก่น
  [15.277698302658044, 104.80419983446996], //อุบลราชธานี
  [19.288243397212522, 97.96458652669538], //แม่ฮ่องสอน
  [16.81861312923952, 100.26064598882839], //พิษณุโลก
  [14.689851945636258, 100.87187796224839], //สระบุรี
  [12.46119715211704, 102.22599447074217], //จันทบุรี
  [16.871194429753924, 99.12504972607032], //ตาก
  [12.676810605685885, 101.06457362751468], //ระยอง
  // [13.652169833271337, 100.25723661068282],
  [14.746085409373775, 98.62527171537481], //กาญจนบุรี
  [13.845771932230118, 100.578198481849], //กรุงเทพมหานะคร
  [7.158342882590448, 100.61120373949488], //สงขลา
  [7.894702300298442, 98.35284958179211], //ภูเก็ต
];
const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setAlertDialog, setAlert } = useContext(AppContext);
  const FileUploaderRef = React.useRef(null);
  const [data, setdata] = React.useState({
    image: {
      invalid: false,
      text: "",
      msg: "",
    },
  });
  const [preview, setpreview] = useState([]);

  const [previewRealtime, setPreviewRealtime] = useState([]);
  const [previewDaily, setPreviewDaily] = useState([]);

  const broadcast = async () => {
    if (!service.isNullOrEmpty(data.image.text)) {
      dispatch(fetchStart());
      console.log("broadcast");
      let _tbbroadcast = await service.postHttp("/broadcast/send", dispatch);
      if (_tbbroadcast.status) {
        if (_tbbroadcast.data.status) {
          setAlertDialog({
            open: true,
            type: "alert",
            msg: "broadcast สำเร็จ",
            Close: (even) => {
              even();
            },
          });
        } else {
          setAlertDialog({
            open: true,
            type: "alert",
            msg: "broadcast ไม่สำเร็จ",
            Close: (even) => {
              even();
            },
          });
        }
      } else {
        setAlertDialog({
          open: true,
          type: "alert",
          msg: "broadcast ไม่สำเร็จ",
          Close: (even) => {
            even();
          },
        });
      }
      dispatch(fetchSuccess());
    } else {
      setAlertDialog({
        open: true,
        type: "alert",
        msg: "broadcast ยังไม่ได้กำหนดรูป",
        Close: (even) => {
          even();
        },
      });
    }
  };
  const gettbbroadcast = async (isCurrent) => {
    dispatch(fetchStart());
    let res = await service.getHttp("/broadcast", dispatch);
    dispatch(fetchSuccess());
    // console.log(res);
    if (res.status) {
      setdata({
        image: {
          text: process.env.REACT_APP_KEY_URLAPI + res.tbBroadcast.imagePath,
        },
      });
    }
  };
  const updatebroadcast = async (image) => {
    dispatch(fetchStart());
    await service.postHttp("/broadcast", { image: image });
    dispatch(fetchSuccess());
    gettbbroadcast(true);

    setAlert({ open: true, type: "success", msg: "บันทึกข้อมูลสำเร็จ" });
  };
  useEffect(() => {
    let isCurrent = true;
    gettbbroadcast(isCurrent);
    return () => {
      isCurrent = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getpreview = async () => {
    dispatch(fetchStart());
    service.getHttp("/broadcast/getPreviewRealtime").then((e) => {
      // console.log(e);
      if (e.status) {
        setPreviewRealtime(e?.data || []);
      }
    });

    let data = await Promise.all(
      list.map(
        (e, i) =>
          new Promise((resolve, reject) => {
            service
              .getHttp(`/broadcast/getPreviewDaily/${e[0]}/${e[1]}`)
              .then((response) => {
                if (response.status) {
                  // Collect results instead of updating state in every iteration
                  setPreviewDaily((e) => [...e, { ...response?.data, i }]);
                  return { ...response?.data, i };
                } else {
                  throw new Error("Failed to fetch preview data");
                }
              })
              .then((result) => {
                // Resolve with the result once the promise is successfully fetched
                resolve(result);
              })
              .catch((error) => {
                // Handle errors
                console.error(`Error for item ${i}:`, error);
                resolve(null); // Resolve with null or some error state to not break Promise.all
              });
          }),
      ),
    ).finally(() => {
      // Dispatch the success action after all the requests complete
      dispatch(fetchSuccess());
    });
  };
  return (
    <PageContainer heading={""}>
      <Grid container xs={12} spacing={2}>
        <Grid
          item
          sm={12}
          style={{
            fontSize: "20px",
            fontWeight: "700",
            padding: 20,
          }}
        >
          <IntlMessages id="pages.manageBroadcast" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          // style={{ padding: "0 20px 0 0", marginBottom: "15px" }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              aspectRatio: 1,
            }}
          >
            <Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  // border: "1px solid red",
                  aspectRatio: 1,
                  borderRadius: 10,
                  maxWidth: "420px",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    position: "absolute",
                    zIndex: 999,
                    width: "48px",
                    aspectRatio: 1,
                    backgroundColor: "rgba(255,255,255, 0.7)",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    right: 10,
                    top: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => FileUploaderRef.current.children[0].click()}
                >
                  <EditIcon
                    sx={{
                      color: "red",
                    }}
                  />
                </Box>
                <Box ref={FileUploaderRef} className={classes.FileUploader}>
                  <FileUploader
                    multple={false}
                    handleChange={(e) => {
                      if (
                        e.type.toLowerCase().includes("png") ||
                        e.type.toLowerCase().includes("jpeg")
                      ) {
                        console.log("e", e);

                        let _URL = window.URL || window.webkitURL;
                        let img = new Image();
                        const objectUrl = _URL.createObjectURL(e);

                        img.onload = function () {
                          if (this.width !== 1024 || this.height !== 1024) {
                            setAlertDialog({
                              open: true,
                              type: "alert",
                              msg: "ขนาดรูปภาพไม่ถูกต้อง กรุณาอัปโหลดรูปภาพใหม่ (ขนาดภาพแนะนำ 1024 * 1024 px)",
                              Close: (even) => {
                                even();
                              },
                            });
                          } else {
                            service.getBase64(e, (result) => {
                              setAlertDialog({
                                open: true,
                                type: "confirm",
                                msg: "ต้องการเปลี่ยนแปลงข้อมูล ใช่หรือไม่?",
                                Close: (even) => {
                                  even();
                                },
                                Ok: (even) => {
                                  even();
                                  updatebroadcast(result);
                                },
                              });
                            });
                          }
                          _URL.revokeObjectURL(objectUrl);
                        };
                        img.src = objectUrl;
                      } else {
                        setAlertDialog({
                          open: true,
                          type: "alert",
                          msg: "ประเภทไฟล์ไม่ถูกต้อง*ไฟล์ที่รองรับ: .JPEG, .PNG",
                          Close: (even) => {
                            even();
                          },
                        });
                      }
                    }}
                    name="image"
                    types={["png", "jpeg", "jpg"]}
                  >
                    <Box
                      className={
                        "dropfile" + (data.image.invalid ? " error" : "")
                      }
                    >
                      <Box
                        className={
                          "dropfile-body " +
                          (service.isNullOrEmpty(data.image.text)
                            ? ""
                            : "image")
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                        }}
                      >
                        {service.isNullOrEmpty(data.image.text) ? (
                          <Box>
                            <Box
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button variant="contained" color="primary">
                                Choose File
                              </Button>
                            </Box>
                            <Box
                              style={{
                                fontSize: "9px",
                                textAlign: "center",
                                marginTop: "5px",
                                color: data.image.invalid ? "red" : "#000",
                              }}
                            >
                              Or drag and drop file
                            </Box>
                            {data.image.invalid ? (
                              <Box
                                style={{
                                  color: "red",
                                  fontSize: "9px",
                                  textAlign: "center",
                                }}
                              >
                                {data.image.msg}
                              </Box>
                            ) : null}
                          </Box>
                        ) : (
                          <img
                            // style={{ height: "100%", objectFit: "contain" }}
                            style={{
                              width: "100%",
                              aspectRatio: 1,
                            }}
                            src={data.image.text}
                            alt="image"
                          />
                        )}
                      </Box>
                    </Box>
                  </FileUploader>
                </Box>
              </Box>
              <Box
                style={{ marginTop: 10, textAlign: "center", color: "#9C9C9C" }}
              >
                (ขนาดภาพ 1024*1024 px)
              </Box>
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={broadcast}
                  style={{
                    width: 100,
                    height: 40,
                  }}
                >
                  Broadcast
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <Box style={{ display: "flex" }}>
              <Box className={classes.lbl} style={{ width: "120px" }}></Box>
              <Box className={classes.lbl} style={{ width: "100px" }}>
                Realtime
              </Box>
              <Box className={classes.lbl} style={{ width: "100px" }}>
                Daily
              </Box>
            </Box>
            <PM25UC
              name="เชียงใหม่"
              index={0}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="น่าน"
              index={1}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="ขอนแก่น"
              index={2}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="อุบลราชธานี"
              index={3}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="แม่ฮ่องสอน"
              index={4}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="พิษณุโลก"
              index={5}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="สระบุรี"
              index={6}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="จันทบุรี"
              index={7}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="ตาก"
              index={8}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="ระยอง"
              index={9}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="กาญจนบุรี"
              index={10}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="กรุงเทพมหานคร"
              index={11}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="สงขลา"
              index={12}
              {...{ previewRealtime, previewDaily }}
            />
            <PM25UC
              name="ภูเก็ต"
              index={13}
              {...{ previewRealtime, previewDaily }}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={getpreview}
              style={{
                width: 100,
                height: 40,
              }}
            >
              preview
            </Button>
          </Box>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Index;
