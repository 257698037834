import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Fragment } from "react";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",

    "& .Mui-error": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#f44336",
      },
    },
    "& textarea": {
      fontSize: "16px",
      fontWeight: "300",
    },
    "& textarea::placeholder": {
      fontSize: "14px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: "10px",
      margin: "5px 0 5px 0",
      color: "#f44336",
    },
    "& .Mui-focused fieldset": {
      borderWidth: "1px !important",
      borderColor: theme.palette.custom.main + " !important",
    },
  },
}));
const Message = ({ onChange, data }) => {
  const Styles = useStyles();
  return (
    <Fragment>
      <Grid
        item
        {...{ xs: 12 }}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="inherit">
          ข้อความตอบกลับ <span style={{ color: "red" }}>*</span>
        </Typography>
        <Typography
          variant="caption"
          style={{
            color:
              (data?.message || "").length == 255 ? "#f16666ba" : "#e3e3e3",
          }}
        >
          {`${(data?.message || "").length}/255`}
        </Typography>
      </Grid>
      <Grid item {...{ xs: 12 }}>
        <FormControl variant="outlined" className={Styles.formControl}>
          <TextField
            variant="outlined"
            multiline
            minRows={3}
            id="message"
            name="message"
            disabled={false}
            type={"text"}
            onChange={(props) => onChange(props.target)}
            value={data?.message}
            inputProps={{
              maxLength: 255,
            }}
            placeholder="ระบุข้อความตอบกลับ"
            error={data?.error?.message}
            helperText={data?.error?.message}
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};

export default Message;
