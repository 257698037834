import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";

import { Dialog, Button, Box } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import AppContext from "../@jumbo/components/contextProvider/AppContextProvider/AppContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  Paper: {
    "& .MuiDialog-paper": {
      minWidth: "400px",
      border: "1px solid " + theme.palette.custom.main,
    },
  },
  Ok: {
    border: "1px solid " + theme.palette.custom.main,
    backgroundColor: theme.palette.custom.main,
    color: "#FFF",
    height: "30px",
    "&:hover": {
      border: "1px solid " + theme.palette.custom.main,
      backgroundColor: theme.palette.custom.main,
    },
  },
  Close: {
    border: "1px solid " + theme.palette.custom.main,
    backgroundColor: "#FFF",
    color: theme.palette.custom.main,
    height: "30px",
    "&:hover": {
      border: "1px solid " + theme.palette.custom.main,
      backgroundColor: "#FFF",
    },
  },
}));
const AlertDialogSlide = () => {
  const classes = useStyles();
  const { AlertDialog, setAlertDialog } = useContext(AppContext);

  const handleClose = () => {
    if (AlertDialog.Close !== undefined) {
      AlertDialog.Close(() => {
        setAlertDialog({ open: false });
      });
    } else {
      setAlertDialog({ open: false });
    }
  };
  const handleOk = () => {
    if (AlertDialog.Ok !== undefined) {
      AlertDialog.Ok(() => {
        setAlertDialog({ open: false });
      });
    } else {
      setAlertDialog({ open: false });
    }
  };

  return (
    <Dialog
      open={AlertDialog.open}
      // open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.Paper}
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box style={{ color: "#9E76B4" }}>แจ้งเตือน</Box>
          <Box
            style={{ color: "#9E76B4", cursor: "pointer" }}
            onClick={handleClose}
          >
            x
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          style={{ color: "#000", fontSize: "14px" }}
        >
          {AlertDialog.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {AlertDialog.type === "confirm" ? (
          <>
            <Button
              onClick={handleClose}
              color="primary"
              className={classes.Close}
              style={{ fontSize: "14px" }}
            >
              <Box>ไม่</Box>
            </Button>
            <Button
              onClick={handleOk}
              color="primary"
              className={classes.Ok}
              style={{ fontSize: "14px" }}
            >
              <Box>ใช่</Box>
            </Button>
          </>
        ) : (
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.Ok}
            style={{ fontSize: "14px" }}
          >
            <Box>ตกลง</Box>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AlertDialogSlide;
