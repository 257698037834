import React, { useEffect, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchStart, fetchSuccess } from "../../../redux/actions";
import service from "undefined-service-web";

import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Box,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import AppContext from "../../../@jumbo/components/contextProvider/AppContextProvider/AppContext";
import MaterialTableUC from "../../../components/MaterialTableUC";

const useStyles = makeStyles((theme) => ({
  search: {
    "& .MuiInputBase-root,input": {
      // height: "30px",
      borderRadius: "20px",
      fontSize: "14px",
    },
    width: "100%",
    maxWidth: "600px",
  },
}));
const Index = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setAlertDialog, setAlert } = useContext(AppContext);
  const tableRef = useRef(0);
  const columns = [
    {
      title: "ลำดับ",
      field: "index",
      cellStyle: { maxWidth: "80px", width: "80px", textAlign: "center" },
      maxWidth: "80px",
      width: "80px",
      headerStyle: {
        maxWidth: "80px",
        width: "80px",
        textAlign: "center",
        fontWeight: "bold",
      },
    },
    {
      title: "ลำดับการแสดง",
      field: "listno",
      cellStyle: { maxWidth: "auto", width: "150px", textAlign: "center" },
      maxWidth: "auto",
      width: "auto",
      headerStyle: {
        maxWidth: "auto",
        width: "auto",
        fontWeight: "bold",
        textAlign: "center",
      },
    },
    {
      title: "หัวข้อ",
      field: "title",
      cellStyle: { maxWidth: "auto", width: "auto", wordBreak: "break-all" },
      maxWidth: "auto",
      width: "auto",
      headerStyle: { maxWidth: "auto", width: "auto", fontWeight: "bold" },
    },

    {
      title: "จัดการ",
      render: (e, r) => {
        return (
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Box style={{ padding: "0 5px" }}>
              <NavLink to={"/carouselinfo/" + e.id} style={{ color: "#000" }}>
                <Edit />
              </NavLink>
            </Box>
            <Box style={{ padding: "0 5px" }}>
              <DeleteOutline
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // console.log(e)
                  setAlertDialog({
                    open: true,
                    type: "confirm",
                    msg: "ต้องการลบข้อมูล ใช่หรือไม่?",
                    Close: (even) => {
                      even();
                    },
                    Ok: (even) => {
                      even();
                      doDelete(e.id);
                    },
                  });
                }}
              />
            </Box>
          </Box>
        );
      },
      cellStyle: { maxWidth: "80px", width: "80px" },
      maxWidth: "80px",
      width: "80px",
      headerStyle: { maxWidth: "80px", width: "80px", fontWeight: "bold" },
    },
  ];
  const [data, setdata] = React.useState([]);
  const [list, setlist] = React.useState([]);
  const [searchText, setsearchText] = React.useState();
  const changeSearchText = (e) => {
    let text = e.target.value;
    if (service.isNullOrEmpty(text)) {
      setsearchText(null);
      setlist(data);
    } else {
      setsearchText(text);
      let list = service.lodash.filter([...data], (e, i) => {
        if (
          e.listno.toString().includes(text) ||
          e.title.includes(text) ||
          (i + 1).toString().includes(text)
        ) {
          return e;
        }
      });
      setlist(list);
    }
  };
  const gettbNews = async (isCurrent) => {
    dispatch(fetchStart());
    let res = await service.getHttp("/carousels", dispatch);
    dispatch(fetchSuccess());

    if (res.status) {
      let tbNews = res.tbNews;
      service.lodash.filter([...tbNews], (e, i) => {
        e.index = i + 1;
      });
      setdata(tbNews);
      setlist(tbNews);
    }
  };
  const doDelete = async (Id, callback) => {
    dispatch(fetchStart());
    let _delete = await service.postHttp("/carousels/delete", { id: Id });
    dispatch(fetchSuccess());
    if (_delete.status) {
      if (_delete.data.status) {
        gettbNews();
        setAlert({ open: true, type: "success", msg: "ลบข้อมูลสำเร็จ" });
      } else {
        setAlertDialog({
          open: true,
          type: "alert",
          msg: "ลบข้อมูล ไม่สำเร็จ",
          Close: (even) => {
            even();
          },
        });
      }
    } else {
      setAlertDialog({
        open: true,
        type: "alert",
        msg: "ลบข้อมูล ไม่สำเร็จ",
        Close: (even) => {
          even();
        },
      });
    }
  };
  useEffect(() => {
    let isCurrent = true;
    gettbNews(isCurrent);
    return () => {
      isCurrent = false;
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageContainer heading={""}>
      <Grid
        container
        style={{
          padding: 20,
        }}
        spacing={2}
      >
        <Grid
          item
          sm={12}
          style={{
            fontSize: "20px",
            fontWeight: "700",
          }}
        >
          <IntlMessages id="pages.manageCarousel" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            id="input-with-icon-search"
            type="search"
            className={classes.search}
            onChange={changeSearchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: "14px" }} />
                </InputAdornment>
              ),
            }}
            placeholder="search"
            defaultValue={searchText}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <NavLink to="/carouselinfo" style={{ color: "#FFF" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                width: 100,
                height: 40,
              }}
            >
              <IntlMessages id="appModule.add" />
            </Button>
          </NavLink>
        </Grid>
        <Grid item xs={12}>
          <MaterialTableUC
            columns={columns}
            list={list}
            tableRef={tableRef}
            pagesize={true}
          />
        </Grid>
      </Grid>
      <Box
        style={{
          position: "absolute",
          bottom: "10px",
          color: "red",
          fontSize: "12px",
        }}
      >
        {
          "หมายเหตุ : ข้อมูลข่าวสารจะถูกนำไปแสดงใน Line Official Account จำนวน 10 รายการเท่านั้น"
        }
      </Box>
    </PageContainer>
  );
};

export default Index;
