export default [
    {
        "label": "พระนคร",
        "value": "1001",
        "P": "10"
    },
    {
        "label": "ดุสิต",
        "value": "1002",
        "P": "10"
    },
    {
        "label": "หนองจอก",
        "value": "1003",
        "P": "10"
    },
    {
        "label": "บางรัก",
        "value": "1004",
        "P": "10"
    },
    {
        "label": "บางเขน",
        "value": "1005",
        "P": "10"
    },
    {
        "label": "บางกะปิ",
        "value": "1006",
        "P": "10"
    },
    {
        "label": "ปทุมวัน",
        "value": "1007",
        "P": "10"
    },
    {
        "label": "ป้อมปราบศัตรูพ่าย",
        "value": "1008",
        "P": "10"
    },
    {
        "label": "พระโขนง",
        "value": "1009",
        "P": "10"
    },
    {
        "label": "มีนบุรี",
        "value": "1010",
        "P": "10"
    },
    {
        "label": "ลาดกระบัง",
        "value": "1011",
        "P": "10"
    },
    {
        "label": "ยานนาวา",
        "value": "1012",
        "P": "10"
    },
    {
        "label": "สัมพันธวงศ์",
        "value": "1013",
        "P": "10"
    },
    {
        "label": "พญาไท",
        "value": "1014",
        "P": "10"
    },
    {
        "label": "ธนบุรี",
        "value": "1015",
        "P": "10"
    },
    {
        "label": "บางกอกใหญ่",
        "value": "1016",
        "P": "10"
    },
    {
        "label": "ห้วยขวาง",
        "value": "1017",
        "P": "10"
    },
    {
        "label": "คลองสาน",
        "value": "1018",
        "P": "10"
    },
    {
        "label": "ตลิ่งชัน",
        "value": "1019",
        "P": "10"
    },
    {
        "label": "บางกอกน้อย",
        "value": "1020",
        "P": "10"
    },
    {
        "label": "บางขุนเทียน",
        "value": "1021",
        "P": "10"
    },
    {
        "label": "ภาษีเจริญ",
        "value": "1022",
        "P": "10"
    },
    {
        "label": "หนองแขม",
        "value": "1023",
        "P": "10"
    },
    {
        "label": "ราษฎร์บูรณะ",
        "value": "1024",
        "P": "10"
    },
    {
        "label": "บางพลัด",
        "value": "1025",
        "P": "10"
    },
    {
        "label": "ดินแดง",
        "value": "1026",
        "P": "10"
    },
    {
        "label": "บึงกุ่ม",
        "value": "1027",
        "P": "10"
    },
    {
        "label": "สาทร",
        "value": "1028",
        "P": "10"
    },
    {
        "label": "บางซื่อ",
        "value": "1029",
        "P": "10"
    },
    {
        "label": "จตุจักร",
        "value": "1030",
        "P": "10"
    },
    {
        "label": "บางคอแหลม",
        "value": "1031",
        "P": "10"
    },
    {
        "label": "ประเวศ",
        "value": "1032",
        "P": "10"
    },
    {
        "label": "คลองเตย",
        "value": "1033",
        "P": "10"
    },
    {
        "label": "สวนหลวง",
        "value": "1034",
        "P": "10"
    },
    {
        "label": "จอมทอง",
        "value": "1035",
        "P": "10"
    },
    {
        "label": "ดอนเมือง",
        "value": "1036",
        "P": "10"
    },
    {
        "label": "ราชเทวี",
        "value": "1037",
        "P": "10"
    },
    {
        "label": "ลาดพร้าว",
        "value": "1038",
        "P": "10"
    },
    {
        "label": "วัฒนา",
        "value": "1039",
        "P": "10"
    },
    {
        "label": "บางแค",
        "value": "1040",
        "P": "10"
    },
    {
        "label": "หลักสี่",
        "value": "1041",
        "P": "10"
    },
    {
        "label": "สายไหม",
        "value": "1042",
        "P": "10"
    },
    {
        "label": "คันนายาว",
        "value": "1043",
        "P": "10"
    },
    {
        "label": "สะพานสูง",
        "value": "1044",
        "P": "10"
    },
    {
        "label": "วังทองหลาง",
        "value": "1045",
        "P": "10"
    },
    {
        "label": "คลองสามวา",
        "value": "1046",
        "P": "10"
    },
    {
        "label": "บางนา",
        "value": "1047",
        "P": "10"
    },
    {
        "label": "ทวีวัฒนา",
        "value": "1048",
        "P": "10"
    },
    {
        "label": "ทุ่งครุ",
        "value": "1049",
        "P": "10"
    },
    {
        "label": "บางบอน",
        "value": "1050",
        "P": "10"
    },
    {
        "label": "เมืองสมุทรปราการ",
        "value": "1101",
        "P": "11"
    },
    {
        "label": "บางบ่อ",
        "value": "1102",
        "P": "11"
    },
    {
        "label": "บางพลี",
        "value": "1103",
        "P": "11"
    },
    {
        "label": "พระประแดง",
        "value": "1104",
        "P": "11"
    },
    {
        "label": "พระสมุทรเจดีย์",
        "value": "1105",
        "P": "11"
    },
    {
        "label": "บางเสาธง",
        "value": "1106",
        "P": "11"
    },
    {
        "label": "เมืองนนทบุรี",
        "value": "1201",
        "P": "12"
    },
    {
        "label": "บางกรวย",
        "value": "1202",
        "P": "12"
    },
    {
        "label": "บางใหญ่",
        "value": "1203",
        "P": "12"
    },
    {
        "label": "บางบัวทอง",
        "value": "1204",
        "P": "12"
    },
    {
        "label": "ไทรน้อย",
        "value": "1205",
        "P": "12"
    },
    {
        "label": "ปากเกร็ด",
        "value": "1206",
        "P": "12"
    },
    {
        "label": "เมืองปทุมธานี",
        "value": "1301",
        "P": "13"
    },
    {
        "label": "คลองหลวง",
        "value": "1302",
        "P": "13"
    },
    {
        "label": "ธัญบุรี",
        "value": "1303",
        "P": "13"
    },
    {
        "label": "หนองเสือ",
        "value": "1304",
        "P": "13"
    },
    {
        "label": "ลาดหลุมแก้ว",
        "value": "1305",
        "P": "13"
    },
    {
        "label": "ลำลูกกา",
        "value": "1306",
        "P": "13"
    },
    {
        "label": "สามโคก",
        "value": "1307",
        "P": "13"
    },
    {
        "label": "พระนครศรีอยุธยา",
        "value": "1401",
        "P": "14"
    },
    {
        "label": "ท่าเรือ",
        "value": "1402",
        "P": "14"
    },
    {
        "label": "นครหลวง",
        "value": "1403",
        "P": "14"
    },
    {
        "label": "บางไทร",
        "value": "1404",
        "P": "14"
    },
    {
        "label": "บางบาล",
        "value": "1405",
        "P": "14"
    },
    {
        "label": "บางปะอิน",
        "value": "1406",
        "P": "14"
    },
    {
        "label": "บางปะหัน",
        "value": "1407",
        "P": "14"
    },
    {
        "label": "ผักไห่",
        "value": "1408",
        "P": "14"
    },
    {
        "label": "ภาชี",
        "value": "1409",
        "P": "14"
    },
    {
        "label": "ลาดบัวหลวง",
        "value": "1410",
        "P": "14"
    },
    {
        "label": "วังน้อย",
        "value": "1411",
        "P": "14"
    },
    {
        "label": "เสนา",
        "value": "1412",
        "P": "14"
    },
    {
        "label": "บางซ้าย",
        "value": "1413",
        "P": "14"
    },
    {
        "label": "อุทัย",
        "value": "1414",
        "P": "14"
    },
    {
        "label": "มหาราช",
        "value": "1415",
        "P": "14"
    },
    {
        "label": "บ้านแพรก",
        "value": "1416",
        "P": "14"
    },
    {
        "label": "เมืองอ่างทอง",
        "value": "1501",
        "P": "15"
    },
    {
        "label": "ไชโย",
        "value": "1502",
        "P": "15"
    },
    {
        "label": "ป่าโมก",
        "value": "1503",
        "P": "15"
    },
    {
        "label": "โพธิ์ทอง",
        "value": "1504",
        "P": "15"
    },
    {
        "label": "แสวงหา",
        "value": "1505",
        "P": "15"
    },
    {
        "label": "วิเศษชัยชาญ",
        "value": "1506",
        "P": "15"
    },
    {
        "label": "สามโก้",
        "value": "1507",
        "P": "15"
    },
    {
        "label": "เมืองลพบุรี",
        "value": "1601",
        "P": "16"
    },
    {
        "label": "พัฒนานิคม",
        "value": "1602",
        "P": "16"
    },
    {
        "label": "โคกสำโรง",
        "value": "1603",
        "P": "16"
    },
    {
        "label": "ชัยบาดาล",
        "value": "1604",
        "P": "16"
    },
    {
        "label": "ท่าวุ้ง",
        "value": "1605",
        "P": "16"
    },
    {
        "label": "บ้านหมี่",
        "value": "1606",
        "P": "16"
    },
    {
        "label": "ท่าหลวง",
        "value": "1607",
        "P": "16"
    },
    {
        "label": "สระโบสถ์",
        "value": "1608",
        "P": "16"
    },
    {
        "label": "โคกเจริญ",
        "value": "1609",
        "P": "16"
    },
    {
        "label": "ลำสนธิ",
        "value": "1610",
        "P": "16"
    },
    {
        "label": "หนองม่วง",
        "value": "1611",
        "P": "16"
    },
    {
        "label": "เมืองสิงห์บุรี",
        "value": "1701",
        "P": "17"
    },
    {
        "label": "บางระจัน",
        "value": "1702",
        "P": "17"
    },
    {
        "label": "ค่ายบางระจัน",
        "value": "1703",
        "P": "17"
    },
    {
        "label": "พรหมบุรี",
        "value": "1704",
        "P": "17"
    },
    {
        "label": "ท่าช้าง",
        "value": "1705",
        "P": "17"
    },
    {
        "label": "อินทร์บุรี",
        "value": "1706",
        "P": "17"
    },
    {
        "label": "เมืองชัยนาท",
        "value": "1801",
        "P": "18"
    },
    {
        "label": "มโนรมย์",
        "value": "1802",
        "P": "18"
    },
    {
        "label": "วัดสิงห์",
        "value": "1803",
        "P": "18"
    },
    {
        "label": "สรรพยา",
        "value": "1804",
        "P": "18"
    },
    {
        "label": "สรรคบุรี",
        "value": "1805",
        "P": "18"
    },
    {
        "label": "หันคา",
        "value": "1806",
        "P": "18"
    },
    {
        "label": "หนองมะโมง",
        "value": "1807",
        "P": "18"
    },
    {
        "label": "เนินขาม",
        "value": "1808",
        "P": "18"
    },
    {
        "label": "เมืองสระบุรี",
        "value": "1901",
        "P": "19"
    },
    {
        "label": "แก่งคอย",
        "value": "1902",
        "P": "19"
    },
    {
        "label": "หนองแค",
        "value": "1903",
        "P": "19"
    },
    {
        "label": "วิหารแดง",
        "value": "1904",
        "P": "19"
    },
    {
        "label": "หนองแซง",
        "value": "1905",
        "P": "19"
    },
    {
        "label": "บ้านหมอ",
        "value": "1906",
        "P": "19"
    },
    {
        "label": "ดอนพุด",
        "value": "1907",
        "P": "19"
    },
    {
        "label": "หนองโดน",
        "value": "1908",
        "P": "19"
    },
    {
        "label": "พระพุทธบาท",
        "value": "1909",
        "P": "19"
    },
    {
        "label": "เสาไห้",
        "value": "1910",
        "P": "19"
    },
    {
        "label": "มวกเหล็ก",
        "value": "1911",
        "P": "19"
    },
    {
        "label": "วังม่วง",
        "value": "1912",
        "P": "19"
    },
    {
        "label": "เฉลิมพระเกียรติ",
        "value": "1913",
        "P": "19"
    },
    {
        "label": "เมืองชลบุรี",
        "value": "2001",
        "P": "20"
    },
    {
        "label": "บ้านบึง",
        "value": "2002",
        "P": "20"
    },
    {
        "label": "หนองใหญ่",
        "value": "2003",
        "P": "20"
    },
    {
        "label": "บางละมุง",
        "value": "2004",
        "P": "20"
    },
    {
        "label": "พานทอง",
        "value": "2005",
        "P": "20"
    },
    {
        "label": "พนัสนิคม",
        "value": "2006",
        "P": "20"
    },
    {
        "label": "ศรีราชา",
        "value": "2007",
        "P": "20"
    },
    {
        "label": "เกาะสีชัง",
        "value": "2008",
        "P": "20"
    },
    {
        "label": "สัตหีบ",
        "value": "2009",
        "P": "20"
    },
    {
        "label": "บ่อทอง",
        "value": "2010",
        "P": "20"
    },
    {
        "label": "เกาะจันทร์",
        "value": "2011",
        "P": "20"
    },
    {
        "label": "เมืองระยอง",
        "value": "2101",
        "P": "21"
    },
    {
        "label": "บ้านฉาง",
        "value": "2102",
        "P": "21"
    },
    {
        "label": "แกลง",
        "value": "2103",
        "P": "21"
    },
    {
        "label": "วังจันทร์",
        "value": "2104",
        "P": "21"
    },
    {
        "label": "บ้านค่าย",
        "value": "2105",
        "P": "21"
    },
    {
        "label": "ปลวกแดง",
        "value": "2106",
        "P": "21"
    },
    {
        "label": "เขาชะเมา",
        "value": "2107",
        "P": "21"
    },
    {
        "label": "นิคมพัฒนา",
        "value": "2108",
        "P": "21"
    },
    {
        "label": "เมืองจันทบุรี",
        "value": "2201",
        "P": "22"
    },
    {
        "label": "ขลุง",
        "value": "2202",
        "P": "22"
    },
    {
        "label": "ท่าใหม่",
        "value": "2203",
        "P": "22"
    },
    {
        "label": "โป่งน้ำร้อน",
        "value": "2204",
        "P": "22"
    },
    {
        "label": "มะขาม",
        "value": "2205",
        "P": "22"
    },
    {
        "label": "แหลมสิงห์",
        "value": "2206",
        "P": "22"
    },
    {
        "label": "สอยดาว",
        "value": "2207",
        "P": "22"
    },
    {
        "label": "แก่งหางแมว",
        "value": "2208",
        "P": "22"
    },
    {
        "label": "นายายอาม",
        "value": "2209",
        "P": "22"
    },
    {
        "label": "เขาคิชฌกูฏ",
        "value": "2210",
        "P": "22"
    },
    {
        "label": "เมืองตราด",
        "value": "2301",
        "P": "23"
    },
    {
        "label": "คลองใหญ่",
        "value": "2302",
        "P": "23"
    },
    {
        "label": "เขาสมิง",
        "value": "2303",
        "P": "23"
    },
    {
        "label": "บ่อไร่",
        "value": "2304",
        "P": "23"
    },
    {
        "label": "แหลมงอบ",
        "value": "2305",
        "P": "23"
    },
    {
        "label": "เกาะกูด",
        "value": "2306",
        "P": "23"
    },
    {
        "label": "เกาะช้าง",
        "value": "2307",
        "P": "23"
    },
    {
        "label": "เมืองฉะเชิงเทรา",
        "value": "2401",
        "P": "24"
    },
    {
        "label": "บางคล้า",
        "value": "2402",
        "P": "24"
    },
    {
        "label": "บางน้ำเปรี้ยว",
        "value": "2403",
        "P": "24"
    },
    {
        "label": "บางปะกง",
        "value": "2404",
        "P": "24"
    },
    {
        "label": "บ้านโพธิ์",
        "value": "2405",
        "P": "24"
    },
    {
        "label": "พนมสารคาม",
        "value": "2406",
        "P": "24"
    },
    {
        "label": "ราชสาส์น",
        "value": "2407",
        "P": "24"
    },
    {
        "label": "สนามชัย",
        "value": "2408",
        "P": "24"
    },
    {
        "label": "แปลงยาว",
        "value": "2409",
        "P": "24"
    },
    {
        "label": "ท่าตะเกียบ",
        "value": "2410",
        "P": "24"
    },
    {
        "label": "คลองเขื่อน",
        "value": "2411",
        "P": "24"
    },
    {
        "label": "เมืองปราจีนบุรี",
        "value": "2501",
        "P": "25"
    },
    {
        "label": "กบินทร์บุรี",
        "value": "2502",
        "P": "25"
    },
    {
        "label": "นาดี",
        "value": "2503",
        "P": "25"
    },
    {
        "label": "บ้านสร้าง",
        "value": "2506",
        "P": "25"
    },
    {
        "label": "ประจันตคาม",
        "value": "2507",
        "P": "25"
    },
    {
        "label": "ศรีมหาโพธิ",
        "value": "2508",
        "P": "25"
    },
    {
        "label": "ศรีมโหสถ",
        "value": "2509",
        "P": "25"
    },
    {
        "label": "เมืองนครนายก",
        "value": "2601",
        "P": "26"
    },
    {
        "label": "ปากพลี",
        "value": "2602",
        "P": "26"
    },
    {
        "label": "บ้านนา",
        "value": "2603",
        "P": "26"
    },
    {
        "label": "องครักษ์",
        "value": "2604",
        "P": "26"
    },
    {
        "label": "เมืองสระแก้ว",
        "value": "2701",
        "P": "27"
    },
    {
        "label": "คลองหาด",
        "value": "2702",
        "P": "27"
    },
    {
        "label": "ตาพระยา",
        "value": "2703",
        "P": "27"
    },
    {
        "label": "วังน้ำเย็น",
        "value": "2704",
        "P": "27"
    },
    {
        "label": "วัฒนานคร",
        "value": "2705",
        "P": "27"
    },
    {
        "label": "อรัญประเทศ",
        "value": "2706",
        "P": "27"
    },
    {
        "label": "เขาฉกรรจ์",
        "value": "2707",
        "P": "27"
    },
    {
        "label": "โคกสูง",
        "value": "2708",
        "P": "27"
    },
    {
        "label": "วังสมบูรณ์",
        "value": "2709",
        "P": "27"
    },
    {
        "label": "เมืองนครราชสีมา",
        "value": "3001",
        "P": "30"
    },
    {
        "label": "ครบุรี",
        "value": "3002",
        "P": "30"
    },
    {
        "label": "เสิงสาง",
        "value": "3003",
        "P": "30"
    },
    {
        "label": "คง",
        "value": "3004",
        "P": "30"
    },
    {
        "label": "บ้านเหลื่อม",
        "value": "3005",
        "P": "30"
    },
    {
        "label": "จักราช",
        "value": "3006",
        "P": "30"
    },
    {
        "label": "โชคชัย",
        "value": "3007",
        "P": "30"
    },
    {
        "label": "ด่านขุนทด",
        "value": "3008",
        "P": "30"
    },
    {
        "label": "โนนไทย",
        "value": "3009",
        "P": "30"
    },
    {
        "label": "โนนสูง",
        "value": "3010",
        "P": "30"
    },
    {
        "label": "ขามสะแกแสง",
        "value": "3011",
        "P": "30"
    },
    {
        "label": "บัวใหญ่",
        "value": "3012",
        "P": "30"
    },
    {
        "label": "ประทาย",
        "value": "3013",
        "P": "30"
    },
    {
        "label": "ปักธงชัย",
        "value": "3014",
        "P": "30"
    },
    {
        "label": "พิมาย",
        "value": "3015",
        "P": "30"
    },
    {
        "label": "ห้วยแถลง",
        "value": "3016",
        "P": "30"
    },
    {
        "label": "ชุมพวง",
        "value": "3017",
        "P": "30"
    },
    {
        "label": "สูงเนิน",
        "value": "3018",
        "P": "30"
    },
    {
        "label": "ขามทะเลสอ",
        "value": "3019",
        "P": "30"
    },
    {
        "label": "สีคิ้ว",
        "value": "3020",
        "P": "30"
    },
    {
        "label": "ปากช่อง",
        "value": "3021",
        "P": "30"
    },
    {
        "label": "หนองบุญมาก",
        "value": "3022",
        "P": "30"
    },
    {
        "label": "แก้งสนามนาง",
        "value": "3023",
        "P": "30"
    },
    {
        "label": "โนนแดง",
        "value": "3024",
        "P": "30"
    },
    {
        "label": "วังน้ำเขียว",
        "value": "3025",
        "P": "30"
    },
    {
        "label": "เทพารักษ์",
        "value": "3026",
        "P": "30"
    },
    {
        "label": "เมืองยาง",
        "value": "3027",
        "P": "30"
    },
    {
        "label": "พระทองคำ",
        "value": "3028",
        "P": "30"
    },
    {
        "label": "ลำทะเมนชัย",
        "value": "3029",
        "P": "30"
    },
    {
        "label": "บัวลาย",
        "value": "3030",
        "P": "30"
    },
    {
        "label": "สีดา",
        "value": "3031",
        "P": "30"
    },
    {
        "label": "เฉลิมพระเกียรติ",
        "value": "3032",
        "P": "30"
    },
    {
        "label": "เมืองบุรีรัมย์",
        "value": "3101",
        "P": "31"
    },
    {
        "label": "คูเมือง",
        "value": "3102",
        "P": "31"
    },
    {
        "label": "กระสัง",
        "value": "3103",
        "P": "31"
    },
    {
        "label": "นางรอง",
        "value": "3104",
        "P": "31"
    },
    {
        "label": "หนองกี่",
        "value": "3105",
        "P": "31"
    },
    {
        "label": "ละหานทราย",
        "value": "3106",
        "P": "31"
    },
    {
        "label": "ประโคนชัย",
        "value": "3107",
        "P": "31"
    },
    {
        "label": "บ้านกรวด",
        "value": "3108",
        "P": "31"
    },
    {
        "label": "พุทไธสง",
        "value": "3109",
        "P": "31"
    },
    {
        "label": "ลำปลายมาศ",
        "value": "3110",
        "P": "31"
    },
    {
        "label": "สตึก",
        "value": "3111",
        "P": "31"
    },
    {
        "label": "ปะคำ",
        "value": "3112",
        "P": "31"
    },
    {
        "label": "นาโพธิ์",
        "value": "3113",
        "P": "31"
    },
    {
        "label": "หนองหงส์",
        "value": "3114",
        "P": "31"
    },
    {
        "label": "พลับพลาชัย",
        "value": "3115",
        "P": "31"
    },
    {
        "label": "ห้วยราช",
        "value": "3116",
        "P": "31"
    },
    {
        "label": "โนนสุวรรณ",
        "value": "3117",
        "P": "31"
    },
    {
        "label": "ชำนิ",
        "value": "3118",
        "P": "31"
    },
    {
        "label": "บ้านใหม่ไชยพจน์",
        "value": "3119",
        "P": "31"
    },
    {
        "label": "โนนดินแดง",
        "value": "3120",
        "P": "31"
    },
    {
        "label": "บ้านด่าน",
        "value": "3121",
        "P": "31"
    },
    {
        "label": "แคนดง",
        "value": "3122",
        "P": "31"
    },
    {
        "label": "เฉลิมพระเกียรติ",
        "value": "3123",
        "P": "31"
    },
    {
        "label": "เมืองสุรินทร์",
        "value": "3201",
        "P": "32"
    },
    {
        "label": "ชุมพลบุรี",
        "value": "3202",
        "P": "32"
    },
    {
        "label": "ท่าตูม",
        "value": "3203",
        "P": "32"
    },
    {
        "label": "จอมพระ",
        "value": "3204",
        "P": "32"
    },
    {
        "label": "ปราสาท",
        "value": "3205",
        "P": "32"
    },
    {
        "label": "กาบเชิง",
        "value": "3206",
        "P": "32"
    },
    {
        "label": "รัตนบุรี",
        "value": "3207",
        "P": "32"
    },
    {
        "label": "สนม",
        "value": "3208",
        "P": "32"
    },
    {
        "label": "ศีขรภูมิ",
        "value": "3209",
        "P": "32"
    },
    {
        "label": "สังขะ",
        "value": "3210",
        "P": "32"
    },
    {
        "label": "ลำดวน",
        "value": "3211",
        "P": "32"
    },
    {
        "label": "สำโรงทาบ",
        "value": "3212",
        "P": "32"
    },
    {
        "label": "บัวเชด",
        "value": "3213",
        "P": "32"
    },
    {
        "label": "พนมดงรัก",
        "value": "3214",
        "P": "32"
    },
    {
        "label": "ศรีณรงค์",
        "value": "3215",
        "P": "32"
    },
    {
        "label": "เขวาสินรินทร์",
        "value": "3216",
        "P": "32"
    },
    {
        "label": "โนนนารายณ์",
        "value": "3217",
        "P": "32"
    },
    {
        "label": "เมืองศรีสะเกษ",
        "value": "3301",
        "P": "33"
    },
    {
        "label": "ยางชุมน้อย",
        "value": "3302",
        "P": "33"
    },
    {
        "label": "กันทรารมย์",
        "value": "3303",
        "P": "33"
    },
    {
        "label": "กันทรลักษ์",
        "value": "3304",
        "P": "33"
    },
    {
        "label": "ขุขันธ์",
        "value": "3305",
        "P": "33"
    },
    {
        "label": "ไพรบึง",
        "value": "3306",
        "P": "33"
    },
    {
        "label": "ปรางค์กู่",
        "value": "3307",
        "P": "33"
    },
    {
        "label": "ขุนหาญ",
        "value": "3308",
        "P": "33"
    },
    {
        "label": "ราษีไศล",
        "value": "3309",
        "P": "33"
    },
    {
        "label": "อุทุมพรพิสัย",
        "value": "3310",
        "P": "33"
    },
    {
        "label": "บึงบูรพ์",
        "value": "3311",
        "P": "33"
    },
    {
        "label": "ห้วยทับทัน",
        "value": "3312",
        "P": "33"
    },
    {
        "label": "โนนคูณ",
        "value": "3313",
        "P": "33"
    },
    {
        "label": "ศรีรัตนะ",
        "value": "3314",
        "P": "33"
    },
    {
        "label": "น้ำเกลี้ยง",
        "value": "3315",
        "P": "33"
    },
    {
        "label": "วังหิน",
        "value": "3316",
        "P": "33"
    },
    {
        "label": "ภูสิงห์",
        "value": "3317",
        "P": "33"
    },
    {
        "label": "เมืองจันทร์",
        "value": "3318",
        "P": "33"
    },
    {
        "label": "เบญจลักษ์",
        "value": "3319",
        "P": "33"
    },
    {
        "label": "พยุห์",
        "value": "3320",
        "P": "33"
    },
    {
        "label": "โพธิ์ศรีสุวรรณ",
        "value": "3321",
        "P": "33"
    },
    {
        "label": "ศิลาลาด",
        "value": "3322",
        "P": "33"
    },
    {
        "label": "เมืองอุบลราชธานี",
        "value": "3401",
        "P": "34"
    },
    {
        "label": "ศรีเมืองใหม่",
        "value": "3402",
        "P": "34"
    },
    {
        "label": "โขงเจียม",
        "value": "3403",
        "P": "34"
    },
    {
        "label": "เขื่องใน",
        "value": "3404",
        "P": "34"
    },
    {
        "label": "เขมราฐ",
        "value": "3405",
        "P": "34"
    },
    {
        "label": "เดชอุดม",
        "value": "3407",
        "P": "34"
    },
    {
        "label": "นาจะหลวย",
        "value": "3408",
        "P": "34"
    },
    {
        "label": "น้ำยืน",
        "value": "3409",
        "P": "34"
    },
    {
        "label": "บุณฑริก",
        "value": "3410",
        "P": "34"
    },
    {
        "label": "ตระการพืชผล",
        "value": "3411",
        "P": "34"
    },
    {
        "label": "กุดข้าวปุ้น",
        "value": "3412",
        "P": "34"
    },
    {
        "label": "ม่วงสามสิบ",
        "value": "3414",
        "P": "34"
    },
    {
        "label": "วารินชำราบ",
        "value": "3415",
        "P": "34"
    },
    {
        "label": "พิบูลมังสาหาร",
        "value": "3419",
        "P": "34"
    },
    {
        "label": "ตาลสุม",
        "value": "3420",
        "P": "34"
    },
    {
        "label": "โพธิ์ไทร",
        "value": "3421",
        "P": "34"
    },
    {
        "label": "สำโรง",
        "value": "3422",
        "P": "34"
    },
    {
        "label": "ดอนมดแดง",
        "value": "3424",
        "P": "34"
    },
    {
        "label": "สิรินธร",
        "value": "3425",
        "P": "34"
    },
    {
        "label": "ทุ่งศรีอุดม",
        "value": "3426",
        "P": "34"
    },
    {
        "label": "นาเยีย",
        "value": "3429",
        "P": "34"
    },
    {
        "label": "นาตาล",
        "value": "3430",
        "P": "34"
    },
    {
        "label": "เหล่าเสือโก้ก",
        "value": "3431",
        "P": "34"
    },
    {
        "label": "สว่างวีระวงศ์",
        "value": "3432",
        "P": "34"
    },
    {
        "label": "น้ำขุ่น",
        "value": "3433",
        "P": "34"
    },
    {
        "label": "เมืองยโสธร",
        "value": "3501",
        "P": "35"
    },
    {
        "label": "ทรายมูล",
        "value": "3502",
        "P": "35"
    },
    {
        "label": "กุดชุม",
        "value": "3503",
        "P": "35"
    },
    {
        "label": "คำเขื่อนแก้ว",
        "value": "3504",
        "P": "35"
    },
    {
        "label": "ป่าติ้ว",
        "value": "3505",
        "P": "35"
    },
    {
        "label": "มหาชนะชัย",
        "value": "3506",
        "P": "35"
    },
    {
        "label": "ค้อวัง",
        "value": "3507",
        "P": "35"
    },
    {
        "label": "เลิงนกทา",
        "value": "3508",
        "P": "35"
    },
    {
        "label": "ไทยเจริญ",
        "value": "3509",
        "P": "35"
    },
    {
        "label": "เมืองชัยภูมิ",
        "value": "3601",
        "P": "36"
    },
    {
        "label": "บ้านเขว้า",
        "value": "3602",
        "P": "36"
    },
    {
        "label": "คอนสวรรค์",
        "value": "3603",
        "P": "36"
    },
    {
        "label": "เกษตรสมบูรณ์",
        "value": "3604",
        "P": "36"
    },
    {
        "label": "หนองบัวแดง",
        "value": "3605",
        "P": "36"
    },
    {
        "label": "จัตุรัส",
        "value": "3606",
        "P": "36"
    },
    {
        "label": "บำเหน็จณรงค์",
        "value": "3607",
        "P": "36"
    },
    {
        "label": "หนองบัวระเหว",
        "value": "3608",
        "P": "36"
    },
    {
        "label": "เทพสถิต",
        "value": "3609",
        "P": "36"
    },
    {
        "label": "ภูเขียว",
        "value": "3610",
        "P": "36"
    },
    {
        "label": "บ้านแท่น",
        "value": "3611",
        "P": "36"
    },
    {
        "label": "แก้งคร้อ",
        "value": "3612",
        "P": "36"
    },
    {
        "label": "คอนสาร",
        "value": "3613",
        "P": "36"
    },
    {
        "label": "ภักดีชุมพล",
        "value": "3614",
        "P": "36"
    },
    {
        "label": "เนินสง่า",
        "value": "3615",
        "P": "36"
    },
    {
        "label": "ซับใหญ่",
        "value": "3616",
        "P": "36"
    },
    {
        "label": "เมืองอำนาจเจริญ",
        "value": "3701",
        "P": "37"
    },
    {
        "label": "ชานุมาน",
        "value": "3702",
        "P": "37"
    },
    {
        "label": "ปทุมราชวงศา",
        "value": "3703",
        "P": "37"
    },
    {
        "label": "พนา",
        "value": "3704",
        "P": "37"
    },
    {
        "label": "เสนางคนิคม",
        "value": "3705",
        "P": "37"
    },
    {
        "label": "หัวตะพาน",
        "value": "3706",
        "P": "37"
    },
    {
        "label": "ลืออำนาจ",
        "value": "3707",
        "P": "37"
    },
    {
        "label": "บึงกาฬ",
        "value": "3801",
        "P": "38"
    },
    {
        "label": "พรเจริญ",
        "value": "3802",
        "P": "38"
    },
    {
        "label": "โซ่พิสัย",
        "value": "3803",
        "P": "38"
    },
    {
        "label": "เซกา",
        "value": "3804",
        "P": "38"
    },
    {
        "label": "ปากคาด",
        "value": "3805",
        "P": "38"
    },
    {
        "label": "บึงโขงหลง",
        "value": "3806",
        "P": "38"
    },
    {
        "label": "ศรีวิไล",
        "value": "3807",
        "P": "38"
    },
    {
        "label": "บุ่งคล้า",
        "value": "3808",
        "P": "38"
    },
    {
        "label": "เมืองหนองบัวลำภู",
        "value": "3901",
        "P": "39"
    },
    {
        "label": "นากลาง",
        "value": "3902",
        "P": "39"
    },
    {
        "label": "โนนสัง",
        "value": "3903",
        "P": "39"
    },
    {
        "label": "ศรีบุญเรือง",
        "value": "3904",
        "P": "39"
    },
    {
        "label": "สุวรรณคูหา",
        "value": "3905",
        "P": "39"
    },
    {
        "label": "นาวัง",
        "value": "3906",
        "P": "39"
    },
    {
        "label": "เมืองขอนแก่น",
        "value": "4001",
        "P": "40"
    },
    {
        "label": "บ้านฝาง",
        "value": "4002",
        "P": "40"
    },
    {
        "label": "พระยืน",
        "value": "4003",
        "P": "40"
    },
    {
        "label": "หนองเรือ",
        "value": "4004",
        "P": "40"
    },
    {
        "label": "ชุมแพ",
        "value": "4005",
        "P": "40"
    },
    {
        "label": "สีชมพู",
        "value": "4006",
        "P": "40"
    },
    {
        "label": "น้ำพอง",
        "value": "4007",
        "P": "40"
    },
    {
        "label": "อุบลรัตน์",
        "value": "4008",
        "P": "40"
    },
    {
        "label": "กระนวน",
        "value": "4009",
        "P": "40"
    },
    {
        "label": "บ้านไผ่",
        "value": "4010",
        "P": "40"
    },
    {
        "label": "เปือยน้อย",
        "value": "4011",
        "P": "40"
    },
    {
        "label": "พล",
        "value": "4012",
        "P": "40"
    },
    {
        "label": "แวงใหญ่",
        "value": "4013",
        "P": "40"
    },
    {
        "label": "แวงน้อย",
        "value": "4014",
        "P": "40"
    },
    {
        "label": "หนองสองห้อง",
        "value": "4015",
        "P": "40"
    },
    {
        "label": "ภูเวียง",
        "value": "4016",
        "P": "40"
    },
    {
        "label": "มัญจาคีรี",
        "value": "4017",
        "P": "40"
    },
    {
        "label": "ชนบท",
        "value": "4018",
        "P": "40"
    },
    {
        "label": "เขาสวนกวาง",
        "value": "4019",
        "P": "40"
    },
    {
        "label": "ภูผาม่าน",
        "value": "4020",
        "P": "40"
    },
    {
        "label": "ซำสูง",
        "value": "4021",
        "P": "40"
    },
    {
        "label": "โคกโพธิ์ไชย",
        "value": "4022",
        "P": "40"
    },
    {
        "label": "หนองนาคำ",
        "value": "4023",
        "P": "40"
    },
    {
        "label": "บ้านแฮด",
        "value": "4024",
        "P": "40"
    },
    {
        "label": "โนนศิลา",
        "value": "4025",
        "P": "40"
    },
    {
        "label": "เวียงเก่า",
        "value": "4026",
        "P": "40"
    },
    {
        "label": "เมืองอุดรธานี",
        "value": "4101",
        "P": "41"
    },
    {
        "label": "กุดจับ",
        "value": "4102",
        "P": "41"
    },
    {
        "label": "หนองวัวซอ",
        "value": "4103",
        "P": "41"
    },
    {
        "label": "กุมภวาปี",
        "value": "4104",
        "P": "41"
    },
    {
        "label": "โนนสะอาด",
        "value": "4105",
        "P": "41"
    },
    {
        "label": "หนองหาน",
        "value": "4106",
        "P": "41"
    },
    {
        "label": "ทุ่งฝน",
        "value": "4107",
        "P": "41"
    },
    {
        "label": "ไชยวาน",
        "value": "4108",
        "P": "41"
    },
    {
        "label": "ศรีธาตุ",
        "value": "4109",
        "P": "41"
    },
    {
        "label": "วังสามหมอ",
        "value": "4110",
        "P": "41"
    },
    {
        "label": "บ้านดุง",
        "value": "4111",
        "P": "41"
    },
    {
        "label": "บ้านผือ",
        "value": "4117",
        "P": "41"
    },
    {
        "label": "น้ำโสม",
        "value": "4118",
        "P": "41"
    },
    {
        "label": "เพ็ญ",
        "value": "4119",
        "P": "41"
    },
    {
        "label": "สร้างคอม",
        "value": "4120",
        "P": "41"
    },
    {
        "label": "หนองแสง",
        "value": "4121",
        "P": "41"
    },
    {
        "label": "นายูง",
        "value": "4122",
        "P": "41"
    },
    {
        "label": "พิบูลย์รักษ์",
        "value": "4123",
        "P": "41"
    },
    {
        "label": "กู่แก้ว",
        "value": "4124",
        "P": "41"
    },
    {
        "label": "ประจักษ์ศิลปาคม",
        "value": "4125",
        "P": "41"
    },
    {
        "label": "เมืองเลย",
        "value": "4201",
        "P": "42"
    },
    {
        "label": "นาด้วง",
        "value": "4202",
        "P": "42"
    },
    {
        "label": "เชียงคาน",
        "value": "4203",
        "P": "42"
    },
    {
        "label": "ปากชม",
        "value": "4204",
        "P": "42"
    },
    {
        "label": "ด่านซ้าย",
        "value": "4205",
        "P": "42"
    },
    {
        "label": "นาแห้ว",
        "value": "4206",
        "P": "42"
    },
    {
        "label": "ภูเรือ",
        "value": "4207",
        "P": "42"
    },
    {
        "label": "ท่าลี่",
        "value": "4208",
        "P": "42"
    },
    {
        "label": "วังสะพุง",
        "value": "4209",
        "P": "42"
    },
    {
        "label": "ภูกระดึง",
        "value": "4210",
        "P": "42"
    },
    {
        "label": "ภูหลวง",
        "value": "4211",
        "P": "42"
    },
    {
        "label": "ผาขาว",
        "value": "4212",
        "P": "42"
    },
    {
        "label": "เอราวัณ",
        "value": "4213",
        "P": "42"
    },
    {
        "label": "หนองหิน",
        "value": "4214",
        "P": "42"
    },
    {
        "label": "เมืองหนองคาย",
        "value": "4301",
        "P": "43"
    },
    {
        "label": "ท่าบ่อ",
        "value": "4302",
        "P": "43"
    },
    {
        "label": "โพนพิสัย",
        "value": "4305",
        "P": "43"
    },
    {
        "label": "ศรีเชียงใหม่",
        "value": "4307",
        "P": "43"
    },
    {
        "label": "สังคม",
        "value": "4308",
        "P": "43"
    },
    {
        "label": "สระใคร",
        "value": "4314",
        "P": "43"
    },
    {
        "label": "เฝ้าไร่",
        "value": "4315",
        "P": "43"
    },
    {
        "label": "รัตนวาปี",
        "value": "4316",
        "P": "43"
    },
    {
        "label": "โพธิ์ตาก",
        "value": "4317",
        "P": "43"
    },
    {
        "label": "เมืองมหาสารคาม",
        "value": "4401",
        "P": "44"
    },
    {
        "label": "แกดำ",
        "value": "4402",
        "P": "44"
    },
    {
        "label": "โกสุมพิสัย",
        "value": "4403",
        "P": "44"
    },
    {
        "label": "กันทรวิชัย",
        "value": "4404",
        "P": "44"
    },
    {
        "label": "เชียงยืน",
        "value": "4405",
        "P": "44"
    },
    {
        "label": "บรบือ",
        "value": "4406",
        "P": "44"
    },
    {
        "label": "นาเชือก",
        "value": "4407",
        "P": "44"
    },
    {
        "label": "พยัคฆภูมิพิสัย",
        "value": "4408",
        "P": "44"
    },
    {
        "label": "วาปีปทุม",
        "value": "4409",
        "P": "44"
    },
    {
        "label": "นาดูน",
        "value": "4410",
        "P": "44"
    },
    {
        "label": "ยางสีสุราช",
        "value": "4411",
        "P": "44"
    },
    {
        "label": "กุดรัง",
        "value": "4412",
        "P": "44"
    },
    {
        "label": "ชื่นชม",
        "value": "4413",
        "P": "44"
    },
    {
        "label": "เมืองร้อยเอ็ด",
        "value": "4501",
        "P": "45"
    },
    {
        "label": "เกษตรวิสัย",
        "value": "4502",
        "P": "45"
    },
    {
        "label": "ปทุมรัตต์",
        "value": "4503",
        "P": "45"
    },
    {
        "label": "จตุรพักตรพิมาน",
        "value": "4504",
        "P": "45"
    },
    {
        "label": "ธวัชบุรี",
        "value": "4505",
        "P": "45"
    },
    {
        "label": "พนมไพร",
        "value": "4506",
        "P": "45"
    },
    {
        "label": "โพนทอง",
        "value": "4507",
        "P": "45"
    },
    {
        "label": "โพธิ์ชัย",
        "value": "4508",
        "P": "45"
    },
    {
        "label": "หนองพอก",
        "value": "4509",
        "P": "45"
    },
    {
        "label": "เสลภูมิ",
        "value": "4510",
        "P": "45"
    },
    {
        "label": "สุวรรณภูมิ",
        "value": "4511",
        "P": "45"
    },
    {
        "label": "เมืองสรวง",
        "value": "4512",
        "P": "45"
    },
    {
        "label": "โพนทราย",
        "value": "4513",
        "P": "45"
    },
    {
        "label": "อาจสามารถ",
        "value": "4514",
        "P": "45"
    },
    {
        "label": "เมยวดี",
        "value": "4515",
        "P": "45"
    },
    {
        "label": "ศรีสมเด็จ",
        "value": "4516",
        "P": "45"
    },
    {
        "label": "จังหาร",
        "value": "4517",
        "P": "45"
    },
    {
        "label": "เชียงขวัญ",
        "value": "4518",
        "P": "45"
    },
    {
        "label": "หนองฮี",
        "value": "4519",
        "P": "45"
    },
    {
        "label": "ทุ่งเขาหลวง",
        "value": "4520",
        "P": "45"
    },
    {
        "label": "เมืองกาฬสินธุ์",
        "value": "4601",
        "P": "46"
    },
    {
        "label": "นามน",
        "value": "4602",
        "P": "46"
    },
    {
        "label": "กมลาไสย",
        "value": "4603",
        "P": "46"
    },
    {
        "label": "ร่องคำ",
        "value": "4604",
        "P": "46"
    },
    {
        "label": "กุฉินารายณ์",
        "value": "4605",
        "P": "46"
    },
    {
        "label": "เขาวง",
        "value": "4606",
        "P": "46"
    },
    {
        "label": "ยางตลาด",
        "value": "4607",
        "P": "46"
    },
    {
        "label": "ห้วยเม็ก",
        "value": "4608",
        "P": "46"
    },
    {
        "label": "สหัสขันธ์",
        "value": "4609",
        "P": "46"
    },
    {
        "label": "คำม่วง",
        "value": "4610",
        "P": "46"
    },
    {
        "label": "ท่าคันโท",
        "value": "4611",
        "P": "46"
    },
    {
        "label": "หนองกุงศรี",
        "value": "4612",
        "P": "46"
    },
    {
        "label": "สมเด็จ",
        "value": "4613",
        "P": "46"
    },
    {
        "label": "ห้วยผึ้ง",
        "value": "4614",
        "P": "46"
    },
    {
        "label": "สามชัย",
        "value": "4615",
        "P": "46"
    },
    {
        "label": "นาคู",
        "value": "4616",
        "P": "46"
    },
    {
        "label": "ดอนจาน",
        "value": "4617",
        "P": "46"
    },
    {
        "label": "ฆ้องชัย",
        "value": "4618",
        "P": "46"
    },
    {
        "label": "เมืองสกลนคร",
        "value": "4701",
        "P": "47"
    },
    {
        "label": "กุสุมาลย์",
        "value": "4702",
        "P": "47"
    },
    {
        "label": "กุดบาก",
        "value": "4703",
        "P": "47"
    },
    {
        "label": "พรรณานิคม",
        "value": "4704",
        "P": "47"
    },
    {
        "label": "พังโคน",
        "value": "4705",
        "P": "47"
    },
    {
        "label": "วาริชภูมิ",
        "value": "4706",
        "P": "47"
    },
    {
        "label": "นิคมน้ำอูน",
        "value": "4707",
        "P": "47"
    },
    {
        "label": "วานรนิวาส",
        "value": "4708",
        "P": "47"
    },
    {
        "label": "คำตากล้า",
        "value": "4709",
        "P": "47"
    },
    {
        "label": "บ้านม่วง",
        "value": "4710",
        "P": "47"
    },
    {
        "label": "อากาศอำนวย",
        "value": "4711",
        "P": "47"
    },
    {
        "label": "สว่างแดนดิน",
        "value": "4712",
        "P": "47"
    },
    {
        "label": "ส่องดาว",
        "value": "4713",
        "P": "47"
    },
    {
        "label": "เต่างอย",
        "value": "4714",
        "P": "47"
    },
    {
        "label": "โคกศรีสุพรรณ",
        "value": "4715",
        "P": "47"
    },
    {
        "label": "เจริญศิลป์",
        "value": "4716",
        "P": "47"
    },
    {
        "label": "โพนนาแก้ว",
        "value": "4717",
        "P": "47"
    },
    {
        "label": "ภูพาน",
        "value": "4718",
        "P": "47"
    },
    {
        "label": "เมืองนครพนม",
        "value": "4801",
        "P": "48"
    },
    {
        "label": "ปลาปาก",
        "value": "4802",
        "P": "48"
    },
    {
        "label": "ท่าอุเทน",
        "value": "4803",
        "P": "48"
    },
    {
        "label": "บ้านแพง",
        "value": "4804",
        "P": "48"
    },
    {
        "label": "ธาตุพนม",
        "value": "4805",
        "P": "48"
    },
    {
        "label": "เรณูนคร",
        "value": "4806",
        "P": "48"
    },
    {
        "label": "นาแก",
        "value": "4807",
        "P": "48"
    },
    {
        "label": "ศรีสงคราม",
        "value": "4808",
        "P": "48"
    },
    {
        "label": "นาหว้า",
        "value": "4809",
        "P": "48"
    },
    {
        "label": "โพนสวรรค์",
        "value": "4810",
        "P": "48"
    },
    {
        "label": "นาทม",
        "value": "4811",
        "P": "48"
    },
    {
        "label": "วังยาง",
        "value": "4812",
        "P": "48"
    },
    {
        "label": "เมืองมุกดาหาร",
        "value": "4901",
        "P": "49"
    },
    {
        "label": "นิคมคำสร้อย",
        "value": "4902",
        "P": "49"
    },
    {
        "label": "ดอนตาล",
        "value": "4903",
        "P": "49"
    },
    {
        "label": "ดงหลวง",
        "value": "4904",
        "P": "49"
    },
    {
        "label": "คำชะอี",
        "value": "4905",
        "P": "49"
    },
    {
        "label": "ว่านใหญ่",
        "value": "4906",
        "P": "49"
    },
    {
        "label": "หนองสูง",
        "value": "4907",
        "P": "49"
    },
    {
        "label": "เมืองเชียงใหม่",
        "value": "5001",
        "P": "50"
    },
    {
        "label": "จอมทอง",
        "value": "5002",
        "P": "50"
    },
    {
        "label": "แม่แจ่ม",
        "value": "5003",
        "P": "50"
    },
    {
        "label": "เชียงดาว",
        "value": "5004",
        "P": "50"
    },
    {
        "label": "ดอยสะเก็ด",
        "value": "5005",
        "P": "50"
    },
    {
        "label": "แม่แตง",
        "value": "5006",
        "P": "50"
    },
    {
        "label": "แม่ริม",
        "value": "5007",
        "P": "50"
    },
    {
        "label": "สะเมิง",
        "value": "5008",
        "P": "50"
    },
    {
        "label": "ฝาง",
        "value": "5009",
        "P": "50"
    },
    {
        "label": "แม่อาย",
        "value": "5010",
        "P": "50"
    },
    {
        "label": "พร้าว",
        "value": "5011",
        "P": "50"
    },
    {
        "label": "สันป่าตอง",
        "value": "5012",
        "P": "50"
    },
    {
        "label": "สันกำแพง",
        "value": "5013",
        "P": "50"
    },
    {
        "label": "สันทราย",
        "value": "5014",
        "P": "50"
    },
    {
        "label": "หางดง",
        "value": "5015",
        "P": "50"
    },
    {
        "label": "ฮอด",
        "value": "5016",
        "P": "50"
    },
    {
        "label": "ดอยเต่า",
        "value": "5017",
        "P": "50"
    },
    {
        "label": "อมก๋อย",
        "value": "5018",
        "P": "50"
    },
    {
        "label": "สารภี",
        "value": "5019",
        "P": "50"
    },
    {
        "label": "เวียงแหง",
        "value": "5020",
        "P": "50"
    },
    {
        "label": "ไชยปราการ",
        "value": "5021",
        "P": "50"
    },
    {
        "label": "แม่วาง",
        "value": "5022",
        "P": "50"
    },
    {
        "label": "แม่ออน",
        "value": "5023",
        "P": "50"
    },
    {
        "label": "ดอยหล่อ",
        "value": "5024",
        "P": "50"
    },
    {
        "label": "กัลยาณิวัฒนา",
        "value": "5025",
        "P": "50"
    },
    {
        "label": "เมืองลำพูน",
        "value": "5101",
        "P": "51"
    },
    {
        "label": "แม่ทา",
        "value": "5102",
        "P": "51"
    },
    {
        "label": "บ้านโฮ่ง",
        "value": "5103",
        "P": "51"
    },
    {
        "label": "ลี้",
        "value": "5104",
        "P": "51"
    },
    {
        "label": "ทุ่งหัวช้าง",
        "value": "5105",
        "P": "51"
    },
    {
        "label": "ป่าซาง",
        "value": "5106",
        "P": "51"
    },
    {
        "label": "บ้านธิ",
        "value": "5107",
        "P": "51"
    },
    {
        "label": "เวียงหนองล่อง",
        "value": "5108",
        "P": "51"
    },
    {
        "label": "เมืองลำปาง",
        "value": "5201",
        "P": "52"
    },
    {
        "label": "แม่เมาะ",
        "value": "5202",
        "P": "52"
    },
    {
        "label": "เกาะคา",
        "value": "5203",
        "P": "52"
    },
    {
        "label": "เสริมงาม",
        "value": "5204",
        "P": "52"
    },
    {
        "label": "งาว",
        "value": "5205",
        "P": "52"
    },
    {
        "label": "แจ้ห่ม",
        "value": "5206",
        "P": "52"
    },
    {
        "label": "วังเหนือ",
        "value": "5207",
        "P": "52"
    },
    {
        "label": "เถิน",
        "value": "5208",
        "P": "52"
    },
    {
        "label": "แม่พริก",
        "value": "5209",
        "P": "52"
    },
    {
        "label": "แม่ทะ",
        "value": "5210",
        "P": "52"
    },
    {
        "label": "สบปราบ",
        "value": "5211",
        "P": "52"
    },
    {
        "label": "ห้างฉัตร",
        "value": "5212",
        "P": "52"
    },
    {
        "label": "เมืองปาน",
        "value": "5213",
        "P": "52"
    },
    {
        "label": "เมืองอุตรดิตถ์",
        "value": "5301",
        "P": "53"
    },
    {
        "label": "ตรอน",
        "value": "5302",
        "P": "53"
    },
    {
        "label": "ท่าปลา",
        "value": "5303",
        "P": "53"
    },
    {
        "label": "น้ำปาด",
        "value": "5304",
        "P": "53"
    },
    {
        "label": "ฟากท่า",
        "value": "5305",
        "P": "53"
    },
    {
        "label": "บ้านโคก",
        "value": "5306",
        "P": "53"
    },
    {
        "label": "พิชัย",
        "value": "5307",
        "P": "53"
    },
    {
        "label": "ลับแล",
        "value": "5308",
        "P": "53"
    },
    {
        "label": "ทองแสนขัน",
        "value": "5309",
        "P": "53"
    },
    {
        "label": "เมืองแพร่",
        "value": "5401",
        "P": "54"
    },
    {
        "label": "ร้องกวาง",
        "value": "5402",
        "P": "54"
    },
    {
        "label": "ลอง",
        "value": "5403",
        "P": "54"
    },
    {
        "label": "สูงเม่น",
        "value": "5404",
        "P": "54"
    },
    {
        "label": "เด่นชัย",
        "value": "5405",
        "P": "54"
    },
    {
        "label": "สอง",
        "value": "5406",
        "P": "54"
    },
    {
        "label": "วังชิ้น",
        "value": "5407",
        "P": "54"
    },
    {
        "label": "หนองม่วงไข่",
        "value": "5408",
        "P": "54"
    },
    {
        "label": "เมืองน่าน",
        "value": "5501",
        "P": "55"
    },
    {
        "label": "แม่จริม",
        "value": "5502",
        "P": "55"
    },
    {
        "label": "บ้านหลวง",
        "value": "5503",
        "P": "55"
    },
    {
        "label": "นาน้อย",
        "value": "5504",
        "P": "55"
    },
    {
        "label": "ปัว",
        "value": "5505",
        "P": "55"
    },
    {
        "label": "ท่าวังผา",
        "value": "5506",
        "P": "55"
    },
    {
        "label": "เวียงสา",
        "value": "5507",
        "P": "55"
    },
    {
        "label": "ทุ่งช้าง",
        "value": "5508",
        "P": "55"
    },
    {
        "label": "เชียงกลาง",
        "value": "5509",
        "P": "55"
    },
    {
        "label": "นาหมื่น",
        "value": "5510",
        "P": "55"
    },
    {
        "label": "สันติสุข",
        "value": "5511",
        "P": "55"
    },
    {
        "label": "บ่อเกลือ",
        "value": "5512",
        "P": "55"
    },
    {
        "label": "สองแคว",
        "value": "5513",
        "P": "55"
    },
    {
        "label": "ภูเพียง",
        "value": "5514",
        "P": "55"
    },
    {
        "label": "เฉลิมพระเกียรติ",
        "value": "5515",
        "P": "55"
    },
    {
        "label": "เมืองพะเยา",
        "value": "5601",
        "P": "56"
    },
    {
        "label": "จุน",
        "value": "5602",
        "P": "56"
    },
    {
        "label": "เชียงคำ",
        "value": "5603",
        "P": "56"
    },
    {
        "label": "เชียงม่วน",
        "value": "5604",
        "P": "56"
    },
    {
        "label": "ดอกคำใต้",
        "value": "5605",
        "P": "56"
    },
    {
        "label": "ปง",
        "value": "5606",
        "P": "56"
    },
    {
        "label": "แม่ใจ",
        "value": "5607",
        "P": "56"
    },
    {
        "label": "ภูซาง",
        "value": "5608",
        "P": "56"
    },
    {
        "label": "ภูกามยาว",
        "value": "5609",
        "P": "56"
    },
    {
        "label": "เมืองเชียงราย",
        "value": "5701",
        "P": "57"
    },
    {
        "label": "เวียงชัย",
        "value": "5702",
        "P": "57"
    },
    {
        "label": "เชียงของ",
        "value": "5703",
        "P": "57"
    },
    {
        "label": "เทิง",
        "value": "5704",
        "P": "57"
    },
    {
        "label": "พาน",
        "value": "5705",
        "P": "57"
    },
    {
        "label": "ป่าแดด",
        "value": "5706",
        "P": "57"
    },
    {
        "label": "แม่จัน",
        "value": "5707",
        "P": "57"
    },
    {
        "label": "เชียงแสน",
        "value": "5708",
        "P": "57"
    },
    {
        "label": "แม่สาย",
        "value": "5709",
        "P": "57"
    },
    {
        "label": "แม่สรวย",
        "value": "5710",
        "P": "57"
    },
    {
        "label": "เวียงป่าเป้า",
        "value": "5711",
        "P": "57"
    },
    {
        "label": "พญาเม็งราย",
        "value": "5712",
        "P": "57"
    },
    {
        "label": "เวียงแก่น",
        "value": "5713",
        "P": "57"
    },
    {
        "label": "ขุนตาล",
        "value": "5714",
        "P": "57"
    },
    {
        "label": "แม่ฟ้าหลวง",
        "value": "5715",
        "P": "57"
    },
    {
        "label": "แม่ลาว",
        "value": "5716",
        "P": "57"
    },
    {
        "label": "เวียงเชียงรุ้ง",
        "value": "5717",
        "P": "57"
    },
    {
        "label": "ดอยหลวง",
        "value": "5718",
        "P": "57"
    },
    {
        "label": "เมืองแม่ฮ่องสอน",
        "value": "5801",
        "P": "58"
    },
    {
        "label": "ขุนยวม",
        "value": "5802",
        "P": "58"
    },
    {
        "label": "ปาย",
        "value": "5803",
        "P": "58"
    },
    {
        "label": "แม่สะเรียง",
        "value": "5804",
        "P": "58"
    },
    {
        "label": "แม่ลาน้อย",
        "value": "5805",
        "P": "58"
    },
    {
        "label": "สบเมย",
        "value": "5806",
        "P": "58"
    },
    {
        "label": "ปางมะผ้า",
        "value": "5807",
        "P": "58"
    },
    {
        "label": "เมืองนครสวรรค์",
        "value": "6001",
        "P": "60"
    },
    {
        "label": "โกรกพระ",
        "value": "6002",
        "P": "60"
    },
    {
        "label": "ชุมแสง",
        "value": "6003",
        "P": "60"
    },
    {
        "label": "หนองบัว",
        "value": "6004",
        "P": "60"
    },
    {
        "label": "บรรพตพิสัย",
        "value": "6005",
        "P": "60"
    },
    {
        "label": "เก้าเลี้ยว",
        "value": "6006",
        "P": "60"
    },
    {
        "label": "ตาคลี",
        "value": "6007",
        "P": "60"
    },
    {
        "label": "ท่าตะโก",
        "value": "6008",
        "P": "60"
    },
    {
        "label": "ไพศาลี",
        "value": "6009",
        "P": "60"
    },
    {
        "label": "พยุหะคีรี",
        "value": "6010",
        "P": "60"
    },
    {
        "label": "ลาดยาว",
        "value": "6011",
        "P": "60"
    },
    {
        "label": "ตากฟ้า",
        "value": "6012",
        "P": "60"
    },
    {
        "label": "แม่วงก์",
        "value": "6013",
        "P": "60"
    },
    {
        "label": "แม่เปิน",
        "value": "6014",
        "P": "60"
    },
    {
        "label": "ชุมตาบง",
        "value": "6015",
        "P": "60"
    },
    {
        "label": "เมืองอุทัยธานี",
        "value": "6101",
        "P": "61"
    },
    {
        "label": "ทัพทัน",
        "value": "6102",
        "P": "61"
    },
    {
        "label": "สว่างอารมณ์",
        "value": "6103",
        "P": "61"
    },
    {
        "label": "หนองฉาง",
        "value": "6104",
        "P": "61"
    },
    {
        "label": "หนองขาหย่าง",
        "value": "6105",
        "P": "61"
    },
    {
        "label": "บ้านไร่",
        "value": "6106",
        "P": "61"
    },
    {
        "label": "ลานสัก",
        "value": "6107",
        "P": "61"
    },
    {
        "label": "ห้วยคต",
        "value": "6108",
        "P": "61"
    },
    {
        "label": "เมืองกำแพงเพชร",
        "value": "6201",
        "P": "62"
    },
    {
        "label": "ไทรงาม",
        "value": "6202",
        "P": "62"
    },
    {
        "label": "คลองลาน",
        "value": "6203",
        "P": "62"
    },
    {
        "label": "ขาณุวรลักษบุรี",
        "value": "6204",
        "P": "62"
    },
    {
        "label": "คลองขลุง",
        "value": "6205",
        "P": "62"
    },
    {
        "label": "พรานกระต่าย",
        "value": "6206",
        "P": "62"
    },
    {
        "label": "ลานกระบือ",
        "value": "6207",
        "P": "62"
    },
    {
        "label": "ทรายทองวัฒนา",
        "value": "6208",
        "P": "62"
    },
    {
        "label": "ปางศิลาทอง",
        "value": "6209",
        "P": "62"
    },
    {
        "label": "บึงสามัคคี",
        "value": "6210",
        "P": "62"
    },
    {
        "label": "โกสัมพีนคร",
        "value": "6211",
        "P": "62"
    },
    {
        "label": "เมืองตาก",
        "value": "6301",
        "P": "63"
    },
    {
        "label": "บ้านตาก",
        "value": "6302",
        "P": "63"
    },
    {
        "label": "สามเงา",
        "value": "6303",
        "P": "63"
    },
    {
        "label": "แม่ระมาด",
        "value": "6304",
        "P": "63"
    },
    {
        "label": "ท่าสองยาง",
        "value": "6305",
        "P": "63"
    },
    {
        "label": "แม่สอด",
        "value": "6306",
        "P": "63"
    },
    {
        "label": "พบพระ",
        "value": "6307",
        "P": "63"
    },
    {
        "label": "อุ้มผาง",
        "value": "6308",
        "P": "63"
    },
    {
        "label": "วังเจ้า",
        "value": "6309",
        "P": "63"
    },
    {
        "label": "เมืองสุโขทัย",
        "value": "6401",
        "P": "64"
    },
    {
        "label": "บ้านด่านลานหอย",
        "value": "6402",
        "P": "64"
    },
    {
        "label": "คีรีมาศ",
        "value": "6403",
        "P": "64"
    },
    {
        "label": "กงไกรลาศ",
        "value": "6404",
        "P": "64"
    },
    {
        "label": "ศรีสัชนาลัย",
        "value": "6405",
        "P": "64"
    },
    {
        "label": "ศรีสำโรง",
        "value": "6406",
        "P": "64"
    },
    {
        "label": "สวรรคโลก",
        "value": "6407",
        "P": "64"
    },
    {
        "label": "ศรีนคร",
        "value": "6408",
        "P": "64"
    },
    {
        "label": "ทุ่งเสลี่ยม",
        "value": "6409",
        "P": "64"
    },
    {
        "label": "เมืองพิษณุโลก",
        "value": "6501",
        "P": "65"
    },
    {
        "label": "นครไทย",
        "value": "6502",
        "P": "65"
    },
    {
        "label": "ชาติตระการ",
        "value": "6503",
        "P": "65"
    },
    {
        "label": "บางระกำ",
        "value": "6504",
        "P": "65"
    },
    {
        "label": "บางกระทุ่ม",
        "value": "6505",
        "P": "65"
    },
    {
        "label": "พรหมพิราม",
        "value": "6506",
        "P": "65"
    },
    {
        "label": "วัดโบสถ์",
        "value": "6507",
        "P": "65"
    },
    {
        "label": "วังทอง",
        "value": "6508",
        "P": "65"
    },
    {
        "label": "เนินมะปราง",
        "value": "6509",
        "P": "65"
    },
    {
        "label": "เมืองพิจิตร",
        "value": "6601",
        "P": "66"
    },
    {
        "label": "วังทรายพูน",
        "value": "6602",
        "P": "66"
    },
    {
        "label": "โพธิ์ประทับช้าง",
        "value": "6603",
        "P": "66"
    },
    {
        "label": "ตะพานหิน",
        "value": "6604",
        "P": "66"
    },
    {
        "label": "บางมูลนาก",
        "value": "6605",
        "P": "66"
    },
    {
        "label": "โพทะเล",
        "value": "6606",
        "P": "66"
    },
    {
        "label": "สามง่าม",
        "value": "6607",
        "P": "66"
    },
    {
        "label": "ทับคล้อ",
        "value": "6608",
        "P": "66"
    },
    {
        "label": "สากเหล็ก",
        "value": "6609",
        "P": "66"
    },
    {
        "label": "บึงนาราง",
        "value": "6610",
        "P": "66"
    },
    {
        "label": "ดงเจริญ",
        "value": "6611",
        "P": "66"
    },
    {
        "label": "วชิรบารมี",
        "value": "6612",
        "P": "66"
    },
    {
        "label": "เมืองเพชรบูรณ์",
        "value": "6701",
        "P": "67"
    },
    {
        "label": "ชนแดน",
        "value": "6702",
        "P": "67"
    },
    {
        "label": "หล่มสัก",
        "value": "6703",
        "P": "67"
    },
    {
        "label": "หล่มเก่า",
        "value": "6704",
        "P": "67"
    },
    {
        "label": "วิเชียรบุรี",
        "value": "6705",
        "P": "67"
    },
    {
        "label": "ศรีเทพ",
        "value": "6706",
        "P": "67"
    },
    {
        "label": "หนองไผ่",
        "value": "6707",
        "P": "67"
    },
    {
        "label": "บึงสามพัน",
        "value": "6708",
        "P": "67"
    },
    {
        "label": "น้ำหนาว",
        "value": "6709",
        "P": "67"
    },
    {
        "label": "วังโป่ง",
        "value": "6710",
        "P": "67"
    },
    {
        "label": "เขาค้อ",
        "value": "6711",
        "P": "67"
    },
    {
        "label": "เมืองราชบุรี",
        "value": "7001",
        "P": "70"
    },
    {
        "label": "จอมบึง",
        "value": "7002",
        "P": "70"
    },
    {
        "label": "สวนผึ้ง",
        "value": "7003",
        "P": "70"
    },
    {
        "label": "ดำเนินสะดวก",
        "value": "7004",
        "P": "70"
    },
    {
        "label": "บ้านโป่ง",
        "value": "7005",
        "P": "70"
    },
    {
        "label": "บางแพ",
        "value": "7006",
        "P": "70"
    },
    {
        "label": "โพธาราม",
        "value": "7007",
        "P": "70"
    },
    {
        "label": "ปากท่อ",
        "value": "7008",
        "P": "70"
    },
    {
        "label": "วัดเพลง",
        "value": "7009",
        "P": "70"
    },
    {
        "label": "บ้านคา",
        "value": "7010",
        "P": "70"
    },
    {
        "label": "เมืองกาญจนบุรี",
        "value": "7101",
        "P": "71"
    },
    {
        "label": "ไทรโยค",
        "value": "7102",
        "P": "71"
    },
    {
        "label": "บ่อพลอย",
        "value": "7103",
        "P": "71"
    },
    {
        "label": "ศรีสวัสดิ์",
        "value": "7104",
        "P": "71"
    },
    {
        "label": "ท่ามะกา",
        "value": "7105",
        "P": "71"
    },
    {
        "label": "ท่าม่วง",
        "value": "7106",
        "P": "71"
    },
    {
        "label": "ทองผาภูมิ",
        "value": "7107",
        "P": "71"
    },
    {
        "label": "สังขละบุรี",
        "value": "7108",
        "P": "71"
    },
    {
        "label": "พนมทวน",
        "value": "7109",
        "P": "71"
    },
    {
        "label": "เลาขวัญ",
        "value": "7110",
        "P": "71"
    },
    {
        "label": "ด่านมะขามเตี้ย",
        "value": "7111",
        "P": "71"
    },
    {
        "label": "หนองปรือ",
        "value": "7112",
        "P": "71"
    },
    {
        "label": "ห้วยกระเจา",
        "value": "7113",
        "P": "71"
    },
    {
        "label": "เมืองสุพรรณบุรี",
        "value": "7201",
        "P": "72"
    },
    {
        "label": "เดิมบางนางบวช",
        "value": "7202",
        "P": "72"
    },
    {
        "label": "ด่านช้าง",
        "value": "7203",
        "P": "72"
    },
    {
        "label": "บางปลาม้า",
        "value": "7204",
        "P": "72"
    },
    {
        "label": "ศรีประจันต์",
        "value": "7205",
        "P": "72"
    },
    {
        "label": "ดอนเจดีย์",
        "value": "7206",
        "P": "72"
    },
    {
        "label": "สองพี่น้อง",
        "value": "7207",
        "P": "72"
    },
    {
        "label": "สามชุก",
        "value": "7208",
        "P": "72"
    },
    {
        "label": "อู่ทอง",
        "value": "7209",
        "P": "72"
    },
    {
        "label": "หนองหญ้าไซ",
        "value": "7210",
        "P": "72"
    },
    {
        "label": "เมืองนครปฐม",
        "value": "7301",
        "P": "73"
    },
    {
        "label": "กำแพงแสน",
        "value": "7302",
        "P": "73"
    },
    {
        "label": "นครชัยศรี",
        "value": "7303",
        "P": "73"
    },
    {
        "label": "ดอนตูม",
        "value": "7304",
        "P": "73"
    },
    {
        "label": "บางเลน",
        "value": "7305",
        "P": "73"
    },
    {
        "label": "สามพราน",
        "value": "7306",
        "P": "73"
    },
    {
        "label": "พุทธมณฑล",
        "value": "7307",
        "P": "73"
    },
    {
        "label": "เมืองสมุทรสาคร",
        "value": "7401",
        "P": "74"
    },
    {
        "label": "กระทุ่มแบน",
        "value": "7402",
        "P": "74"
    },
    {
        "label": "บ้านแพ้ว",
        "value": "7403",
        "P": "74"
    },
    {
        "label": "เมืองสมุทรสงคราม",
        "value": "7501",
        "P": "75"
    },
    {
        "label": "บางคนที",
        "value": "7502",
        "P": "75"
    },
    {
        "label": "อัมพวา",
        "value": "7503",
        "P": "75"
    },
    {
        "label": "เมืองเพชรบุรี",
        "value": "7601",
        "P": "76"
    },
    {
        "label": "เขาย้อย",
        "value": "7602",
        "P": "76"
    },
    {
        "label": "หนองหญ้าปล้อง",
        "value": "7603",
        "P": "76"
    },
    {
        "label": "ชะอำ",
        "value": "7604",
        "P": "76"
    },
    {
        "label": "ท่ายาง",
        "value": "7605",
        "P": "76"
    },
    {
        "label": "บ้านลาด",
        "value": "7606",
        "P": "76"
    },
    {
        "label": "บ้านแหลม",
        "value": "7607",
        "P": "76"
    },
    {
        "label": "แก่งกระจาน",
        "value": "7608",
        "P": "76"
    },
    {
        "label": "เมืองประจวบคีรีขันธ์",
        "value": "7701",
        "P": "77"
    },
    {
        "label": "กุยบุรี",
        "value": "7702",
        "P": "77"
    },
    {
        "label": "ทับสะแก",
        "value": "7703",
        "P": "77"
    },
    {
        "label": "บางสะพาน",
        "value": "7704",
        "P": "77"
    },
    {
        "label": "บางสะพานน้อย",
        "value": "7705",
        "P": "77"
    },
    {
        "label": "ปราณบุรี",
        "value": "7706",
        "P": "77"
    },
    {
        "label": "หัวหิน",
        "value": "7707",
        "P": "77"
    },
    {
        "label": "สามร้อยยอด",
        "value": "7708",
        "P": "77"
    },
    {
        "label": "เมืองนครศรีธรรมราช",
        "value": "8001",
        "P": "80"
    },
    {
        "label": "พรหมคีรี",
        "value": "8002",
        "P": "80"
    },
    {
        "label": "ลานสกา",
        "value": "8003",
        "P": "80"
    },
    {
        "label": "ฉวาง",
        "value": "8004",
        "P": "80"
    },
    {
        "label": "พิปูน",
        "value": "8005",
        "P": "80"
    },
    {
        "label": "เชียรใหญ่",
        "value": "8006",
        "P": "80"
    },
    {
        "label": "ชะอวด",
        "value": "8007",
        "P": "80"
    },
    {
        "label": "ท่าศาลา",
        "value": "8008",
        "P": "80"
    },
    {
        "label": "ทุ่งสง",
        "value": "8009",
        "P": "80"
    },
    {
        "label": "นาบอน",
        "value": "8010",
        "P": "80"
    },
    {
        "label": "ทุ่งใหญ่",
        "value": "8011",
        "P": "80"
    },
    {
        "label": "ปากพนัง",
        "value": "8012",
        "P": "80"
    },
    {
        "label": "ร่อนพิบูลย์",
        "value": "8013",
        "P": "80"
    },
    {
        "label": "สิชล",
        "value": "8014",
        "P": "80"
    },
    {
        "label": "ขนอม",
        "value": "8015",
        "P": "80"
    },
    {
        "label": "หัวไทร",
        "value": "8016",
        "P": "80"
    },
    {
        "label": "บางขัน",
        "value": "8017",
        "P": "80"
    },
    {
        "label": "ถ้ำพรรณรา",
        "value": "8018",
        "P": "80"
    },
    {
        "label": "จุฬาภรณ์",
        "value": "8019",
        "P": "80"
    },
    {
        "label": "พระพรหม",
        "value": "8020",
        "P": "80"
    },
    {
        "label": "นบพิตำ",
        "value": "8021",
        "P": "80"
    },
    {
        "label": "ช้างกลาง",
        "value": "8022",
        "P": "80"
    },
    {
        "label": "เฉลิมพระเกียรติ",
        "value": "8023",
        "P": "80"
    },
    {
        "label": "เมืองกระบี่",
        "value": "8101",
        "P": "81"
    },
    {
        "label": "เขาพนม",
        "value": "8102",
        "P": "81"
    },
    {
        "label": "เกาะลันตา",
        "value": "8103",
        "P": "81"
    },
    {
        "label": "คลองท่อม",
        "value": "8104",
        "P": "81"
    },
    {
        "label": "อ่าวลึก",
        "value": "8105",
        "P": "81"
    },
    {
        "label": "ปลายพระยา",
        "value": "8106",
        "P": "81"
    },
    {
        "label": "ลำทับ",
        "value": "8107",
        "P": "81"
    },
    {
        "label": "เหนือคลอง",
        "value": "8108",
        "P": "81"
    },
    {
        "label": "เมืองพังงา",
        "value": "8201",
        "P": "82"
    },
    {
        "label": "เกาะยาว",
        "value": "8202",
        "P": "82"
    },
    {
        "label": "กะปง",
        "value": "8203",
        "P": "82"
    },
    {
        "label": "ตะกั่วทุ่ง",
        "value": "8204",
        "P": "82"
    },
    {
        "label": "ตะกั่วป่า",
        "value": "8205",
        "P": "82"
    },
    {
        "label": "คุระบุรี",
        "value": "8206",
        "P": "82"
    },
    {
        "label": "ทับปุด",
        "value": "8207",
        "P": "82"
    },
    {
        "label": "ท้ายเหมือง",
        "value": "8208",
        "P": "82"
    },
    {
        "label": "เมืองภูเก็ต",
        "value": "8301",
        "P": "83"
    },
    {
        "label": "กะทู้",
        "value": "8302",
        "P": "83"
    },
    {
        "label": "ถลาง",
        "value": "8303",
        "P": "83"
    },
    {
        "label": "เมืองสุราษฎร์ธานี",
        "value": "8401",
        "P": "84"
    },
    {
        "label": "กาญจนดิษฐ์",
        "value": "8402",
        "P": "84"
    },
    {
        "label": "ดอนสัก",
        "value": "8403",
        "P": "84"
    },
    {
        "label": "เกาะสมุย",
        "value": "8404",
        "P": "84"
    },
    {
        "label": "เกาะพะงัน",
        "value": "8405",
        "P": "84"
    },
    {
        "label": "ไชยา",
        "value": "8406",
        "P": "84"
    },
    {
        "label": "ท่าชนะ",
        "value": "8407",
        "P": "84"
    },
    {
        "label": "คีรีรัฐนิคม",
        "value": "8408",
        "P": "84"
    },
    {
        "label": "บ้านตาขุน",
        "value": "8409",
        "P": "84"
    },
    {
        "label": "พนม",
        "value": "8410",
        "P": "84"
    },
    {
        "label": "ท่าฉาง",
        "value": "8411",
        "P": "84"
    },
    {
        "label": "บ้านนาสาร",
        "value": "8412",
        "P": "84"
    },
    {
        "label": "บ้านนาเดิม",
        "value": "8413",
        "P": "84"
    },
    {
        "label": "เคียนซา",
        "value": "8414",
        "P": "84"
    },
    {
        "label": "เวียงสระ",
        "value": "8415",
        "P": "84"
    },
    {
        "label": "พระแสง",
        "value": "8416",
        "P": "84"
    },
    {
        "label": "พุนพิน",
        "value": "8417",
        "P": "84"
    },
    {
        "label": "ชัยบุรี",
        "value": "8418",
        "P": "84"
    },
    {
        "label": "วิภาวดี",
        "value": "8419",
        "P": "84"
    },
    {
        "label": "เมืองระนอง",
        "value": "8501",
        "P": "85"
    },
    {
        "label": "ละอุ่น",
        "value": "8502",
        "P": "85"
    },
    {
        "label": "กะเปอร์",
        "value": "8503",
        "P": "85"
    },
    {
        "label": "กระบุรี",
        "value": "8504",
        "P": "85"
    },
    {
        "label": "สุขสำราญ",
        "value": "8505",
        "P": "85"
    },
    {
        "label": "เมืองชุมพร",
        "value": "8601",
        "P": "86"
    },
    {
        "label": "ท่าแซะ",
        "value": "8602",
        "P": "86"
    },
    {
        "label": "ปะทิว",
        "value": "8603",
        "P": "86"
    },
    {
        "label": "หลังสวน",
        "value": "8604",
        "P": "86"
    },
    {
        "label": "ละแม",
        "value": "8605",
        "P": "86"
    },
    {
        "label": "พะโต๊ะ",
        "value": "8606",
        "P": "86"
    },
    {
        "label": "สวี",
        "value": "8607",
        "P": "86"
    },
    {
        "label": "ทุ่งตะโก",
        "value": "8608",
        "P": "86"
    },
    {
        "label": "เมืองสงขลา",
        "value": "9001",
        "P": "90"
    },
    {
        "label": "สทิงพระ",
        "value": "9002",
        "P": "90"
    },
    {
        "label": "จะนะ",
        "value": "9003",
        "P": "90"
    },
    {
        "label": "นาทวี",
        "value": "9004",
        "P": "90"
    },
    {
        "label": "เทพา",
        "value": "9005",
        "P": "90"
    },
    {
        "label": "สะบ้าย้อย",
        "value": "9006",
        "P": "90"
    },
    {
        "label": "ระโนด",
        "value": "9007",
        "P": "90"
    },
    {
        "label": "กระแสสินธุ์",
        "value": "9008",
        "P": "90"
    },
    {
        "label": "รัตภูมิ",
        "value": "9009",
        "P": "90"
    },
    {
        "label": "สะเดา",
        "value": "9010",
        "P": "90"
    },
    {
        "label": "หาดใหญ่",
        "value": "9011",
        "P": "90"
    },
    {
        "label": "นาหม่อม",
        "value": "9012",
        "P": "90"
    },
    {
        "label": "ควนเนียง",
        "value": "9013",
        "P": "90"
    },
    {
        "label": "บางกล่ำ",
        "value": "9014",
        "P": "90"
    },
    {
        "label": "สิงหนคร",
        "value": "9015",
        "P": "90"
    },
    {
        "label": "คลองหอยโข่ง",
        "value": "9016",
        "P": "90"
    },
    {
        "label": "เมืองสตูล",
        "value": "9101",
        "P": "91"
    },
    {
        "label": "ควนโดน",
        "value": "9102",
        "P": "91"
    },
    {
        "label": "ควนกาหลง",
        "value": "9103",
        "P": "91"
    },
    {
        "label": "ท่าแพ",
        "value": "9104",
        "P": "91"
    },
    {
        "label": "ละงู",
        "value": "9105",
        "P": "91"
    },
    {
        "label": "ทุ่งหว้า",
        "value": "9106",
        "P": "91"
    },
    {
        "label": "มะนัง",
        "value": "9107",
        "P": "91"
    },
    {
        "label": "เมืองตรัง",
        "value": "9201",
        "P": "92"
    },
    {
        "label": "กันตัง",
        "value": "9202",
        "P": "92"
    },
    {
        "label": "ย่านตาขาว",
        "value": "9203",
        "P": "92"
    },
    {
        "label": "ปะเหลียน",
        "value": "9204",
        "P": "92"
    },
    {
        "label": "สิเกา",
        "value": "9205",
        "P": "92"
    },
    {
        "label": "ห้วยยอด",
        "value": "9206",
        "P": "92"
    },
    {
        "label": "วังวิเศษ",
        "value": "9207",
        "P": "92"
    },
    {
        "label": "นาโยง",
        "value": "9208",
        "P": "92"
    },
    {
        "label": "รัษฎา",
        "value": "9209",
        "P": "92"
    },
    {
        "label": "หาดสำราญ",
        "value": "9210",
        "P": "92"
    },
    {
        "label": "เมืองพัทลุง",
        "value": "9301",
        "P": "93"
    },
    {
        "label": "กงหรา",
        "value": "9302",
        "P": "93"
    },
    {
        "label": "เขาชัยสน",
        "value": "9303",
        "P": "93"
    },
    {
        "label": "ตะโหมด",
        "value": "9304",
        "P": "93"
    },
    {
        "label": "ควนขนุน",
        "value": "9305",
        "P": "93"
    },
    {
        "label": "ปากพะยูน",
        "value": "9306",
        "P": "93"
    },
    {
        "label": "ศรีบรรพต",
        "value": "9307",
        "P": "93"
    },
    {
        "label": "ป่าบอน",
        "value": "9308",
        "P": "93"
    },
    {
        "label": "บางแก้ว",
        "value": "9309",
        "P": "93"
    },
    {
        "label": "ป่าพยอม",
        "value": "9310",
        "P": "93"
    },
    {
        "label": "ศรีนครินทร์",
        "value": "9311",
        "P": "93"
    },
    {
        "label": "เมืองปัตตานี",
        "value": "9401",
        "P": "94"
    },
    {
        "label": "โคกโพธิ์",
        "value": "9402",
        "P": "94"
    },
    {
        "label": "หนองจิก",
        "value": "9403",
        "P": "94"
    },
    {
        "label": "ปะนาเระ",
        "value": "9404",
        "P": "94"
    },
    {
        "label": "มายอ",
        "value": "9405",
        "P": "94"
    },
    {
        "label": "ทุ่งยางแดง",
        "value": "9406",
        "P": "94"
    },
    {
        "label": "สายบุรี",
        "value": "9407",
        "P": "94"
    },
    {
        "label": "ไม้แก่น",
        "value": "9408",
        "P": "94"
    },
    {
        "label": "ยะหริ่ง",
        "value": "9409",
        "P": "94"
    },
    {
        "label": "ยะรัง",
        "value": "9410",
        "P": "94"
    },
    {
        "label": "กะพ้อ",
        "value": "9411",
        "P": "94"
    },
    {
        "label": "แม่ลาน",
        "value": "9412",
        "P": "94"
    },
    {
        "label": "เมืองยะลา",
        "value": "9501",
        "P": "95"
    },
    {
        "label": "เบตง",
        "value": "9502",
        "P": "95"
    },
    {
        "label": "บันนังสตา",
        "value": "9503",
        "P": "95"
    },
    {
        "label": "ธารโต",
        "value": "9504",
        "P": "95"
    },
    {
        "label": "ยะหา",
        "value": "9505",
        "P": "95"
    },
    {
        "label": "รามัน",
        "value": "9506",
        "P": "95"
    },
    {
        "label": "กาบัง",
        "value": "9507",
        "P": "95"
    },
    {
        "label": "กรงปีนัง",
        "value": "9508",
        "P": "95"
    },
    {
        "label": "เมืองนราธิวาส",
        "value": "9601",
        "P": "96"
    },
    {
        "label": "ตากใบ",
        "value": "9602",
        "P": "96"
    },
    {
        "label": "บาเจาะ",
        "value": "9603",
        "P": "96"
    },
    {
        "label": "ยี่งอ",
        "value": "9604",
        "P": "96"
    },
    {
        "label": "ระแงะ",
        "value": "9605",
        "P": "96"
    },
    {
        "label": "รือเสาะ",
        "value": "9606",
        "P": "96"
    },
    {
        "label": "ศรีสาคร",
        "value": "9607",
        "P": "96"
    },
    {
        "label": "แว้ง",
        "value": "9608",
        "P": "96"
    },
    {
        "label": "สุคิริน",
        "value": "9609",
        "P": "96"
    },
    {
        "label": "สุไหงโก-ลก",
        "value": "9610",
        "P": "96"
    },
    {
        "label": "สุไหงปาดี",
        "value": "9611",
        "P": "96"
    },
    {
        "label": "จะแนะ",
        "value": "9612",
        "P": "96"
    },
    {
        "label": "เจาะไอร้อง",
        "value": "9613",
        "P": "96"
    }
]