import React from "react";
import { Box, CardMedia } from "@material-ui/core";
import { NavLink } from "react-router-dom";
const FooterLogo = ({ color, ...props }) => {
  const logoUrl =
    color === "white"
      ? "/images/logo-white-symbol.png"
      : "/images/footer-logo.png";

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/">
        <CardMedia src={logoUrl} alt="logo" />
      </NavLink>
    </Box>
  );
};

export default FooterLogo;
