const provinces = [
    {
        "label": "กรุงเทพมหานคร",
        "value": "10"
    },
    {
        "label": "สมุทรปราการ",
        "value": "11"
    },
    {
        "label": "นนทบุรี",
        "value": "12"
    },
    {
        "label": "ปทุมธานี",
        "value": "13"
    },
    {
        "label": "พระนครศรีอยุธยา",
        "value": "14"
    },
    {
        "label": "อ่างทอง",
        "value": "15"
    },
    {
        "label": "ลพบุรี",
        "value": "16"
    },
    {
        "label": "สิงห์บุรี",
        "value": "17"
    },
    {
        "label": "ชัยนาท",
        "value": "18"
    },
    {
        "label": "สระบุรี",
        "value": "19"
    },
    {
        "label": "ชลบุรี",
        "value": "20"
    },
    {
        "label": "ระยอง",
        "value": "21"
    },
    {
        "label": "จันทบุรี",
        "value": "22"
    },
    {
        "label": "ตราด",
        "value": "23"
    },
    {
        "label": "ฉะเชิงเทรา",
        "value": "24"
    },
    {
        "label": "ปราจีนบุรี",
        "value": "25"
    },
    {
        "label": "นครนายก",
        "value": "26"
    },
    {
        "label": "สระแก้ว",
        "value": "27"
    },
    {
        "label": "นครราชสีมา",
        "value": "30"
    },
    {
        "label": "บุรีรัมย์",
        "value": "31"
    },
    {
        "label": "สุรินทร์",
        "value": "32"
    },
    {
        "label": "ศรีสะเกษ",
        "value": "33"
    },
    {
        "label": "อุบลราชธานี",
        "value": "34"
    },
    {
        "label": "ยโสธร",
        "value": "35"
    },
    {
        "label": "ชัยภูมิ",
        "value": "36"
    },
    {
        "label": "อำนาจเจริญ",
        "value": "37"
    },
    {
        "label": "บึงกาฬ",
        "value": "38"
    },
    {
        "label": "หนองบัวลำภู",
        "value": "39"
    },
    {
        "label": "ขอนแก่น",
        "value": "40"
    },
    {
        "label": "อุดรธานี",
        "value": "41"
    },
    {
        "label": "เลย",
        "value": "42"
    },
    {
        "label": "หนองคาย",
        "value": "43"
    },
    {
        "label": "มหาสารคาม",
        "value": "44"
    },
    {
        "label": "ร้อยเอ็ด",
        "value": "45"
    },
    {
        "label": "กาฬสินธุ์",
        "value": "46"
    },
    {
        "label": "สกลนคร",
        "value": "47"
    },
    {
        "label": "นครพนม",
        "value": "48"
    },
    {
        "label": "มุกดาหาร",
        "value": "49"
    },
    {
        "label": "เชียงใหม่",
        "value": "50"
    },
    {
        "label": "ลำพูน",
        "value": "51"
    },
    {
        "label": "ลำปาง",
        "value": "52"
    },
    {
        "label": "อุตรดิตถ์",
        "value": "53"
    },
    {
        "label": "แพร่",
        "value": "54"
    },
    {
        "label": "น่าน",
        "value": "55"
    },
    {
        "label": "พะเยา",
        "value": "56"
    },
    {
        "label": "เชียงราย",
        "value": "57"
    },
    {
        "label": "แม่ฮ่องสอน",
        "value": "58"
    },
    {
        "label": "นครสวรรค์",
        "value": "60"
    },
    {
        "label": "อุทัยธานี",
        "value": "61"
    },
    {
        "label": "กำแพงเพชร",
        "value": "62"
    },
    {
        "label": "ตาก",
        "value": "63"
    },
    {
        "label": "สุโขทัย",
        "value": "64"
    },
    {
        "label": "พิษณุโลก",
        "value": "65"
    },
    {
        "label": "พิจิตร",
        "value": "66"
    },
    {
        "label": "เพชรบูรณ์",
        "value": "67"
    },
    {
        "label": "ราชบุรี",
        "value": "70"
    },
    {
        "label": "กาญจนบุรี",
        "value": "71"
    },
    {
        "label": "สุพรรณบุรี",
        "value": "72"
    },
    {
        "label": "นครปฐม",
        "value": "73"
    },
    {
        "label": "สมุทรสาคร",
        "value": "74"
    },
    {
        "label": "สมุทรสงคราม",
        "value": "75"
    },
    {
        "label": "เพชรบุรี",
        "value": "76"
    },
    {
        "label": "ประจวบคีรีขันธ์",
        "value": "77"
    },
    {
        "label": "นครศรีธรรมราช",
        "value": "80"
    },
    {
        "label": "กระบี่",
        "value": "81"
    },
    {
        "label": "พังงา",
        "value": "82"
    },
    {
        "label": "ภูเก็ต",
        "value": "83"
    },
    {
        "label": "สุราษฎร์ธานี",
        "value": "84"
    },
    {
        "label": "ระนอง",
        "value": "85"
    },
    {
        "label": "ชุมพร",
        "value": "86"
    },
    {
        "label": "สงขลา",
        "value": "90"
    },
    {
        "label": "สตูล",
        "value": "91"
    },
    {
        "label": "ตรัง",
        "value": "92"
    },
    {
        "label": "พัทลุง",
        "value": "93"
    },
    {
        "label": "ปัตตานี",
        "value": "94"
    },
    {
        "label": "ยะลา",
        "value": "95"
    },
    {
        "label": "นราธิวาส",
        "value": "96"
    }
]

export default provinces