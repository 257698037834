import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Fragment } from "react";

const UriUC = ({ classes, onChange, data }) => {
  return (
    <Fragment>
      <Grid item {...{ xs: 12 }}>
        <Typography variant="inherit">
          URL Link <span style={{ color: "red" }}>*</span>
        </Typography>
      </Grid>
      <Grid item {...{ xs: 12 }}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            variant="outlined"
            // multiline
            // minRows={3}
            id="message"
            name="message"
            disabled={false}
            type={"text"}
            onChange={(props) => {
              onChange(props.target);
              // const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
              // const regex = new RegExp(expression);
              // const t = 'www.google.com';

              // if (props.target.value.match(regex)) {

              //     console.log("match")
              // } else {
              //     console.log("No match")
              //     // alert("No match");
              // }
            }}
            value={data?.message}
            inputProps={{
              maxLength: 255,
            }}
            placeholder="ระบุลิงก์"
            error={data?.error?.message}
            helperText={data?.error?.message}
          />
        </FormControl>
      </Grid>
    </Fragment>
  );
};

export default UriUC;
