import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Button,
  Typography,
  Checkbox,
  CardMedia,
} from "@material-ui/core";
import ContentLoader from "../../ContentLoader";
import { alpha, makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CurrentAuthMethod } from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
} from "../../../../redux/actions";
import { setAuthUser } from "../../../../redux/actions/Auth";
import { postHttp } from "../../../../services/http";
import * as fn from "../../../../services/default.service";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2,
    },
  },
  authContent: {
    padding: "0 50px",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: (props) => (props.variant === "default" ? "50%" : "100%"),
      order: 1,
    },
    [theme.breakpoints.up("xl")]: {
      // padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 25,
    marginTop: 25,
    color: theme.palette.text.primary,
    "&.MuiTypography-root": {
      fontSize: "18px !important",
      color: "#000",
    },
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(15px, 12px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(15px, -7px) scale(.75)",
    },
    "& input": {
      padding: "0 15px",
      height: "40px",
    },
    "& .MuiFormLabel-root": { fontSize: "14px" },
    marginBottom: "0px",
    marginTop: "0px",
  },
  textFieldRootError: {
    "& fieldset": {
      borderColor: "red !important",
    },
    "& .MuiInputLabel-root": {
      color: "red  !important",
    },
  },
  formcontrolLabelRoot: {
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      color: "#000",
    },
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default",
}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setrememberMe] = useState(false);
  const [showPassword, setshowPassword] = React.useState(false);
  const [invalid, setInvalid] = useState({
    userName: {
      invalid: false,
      msg: "",
    },
    password: {
      invalid: false,
      msg: "",
    },
  });
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const valid = () => {
    let isvalid = !0;

    if (fn.IsNullOrEmpty(userName)) {
      isvalid = !1;
      setInvalid((pre) => ({
        ...pre,
        userName: { msg: "กรุณาระบุ Username", invalid: !0 },
      }));
    }
    if (fn.IsNullOrEmpty(password)) {
      isvalid = !1;
      setInvalid((pre) => ({
        ...pre,
        password: { msg: "กรุณาระบุ Password", invalid: !0 },
      }));
    }

    return isvalid;
  };
  const onSubmit = async () => {
    // localStorage.setItem('token', "isLogin.data.access_token")
    // localStorage.setItem('auth', "isLogin.data.auth")
    // localStorage.setItem('username', "userName")
    // dispatch(setAuthUser("isLogin.data.access_token"))
    if (valid()) {
      dispatch(fetchStart());
      let isLogin = await postHttp("users/login", {
        userName,
        password,
        rememberMe,
      });
      dispatch(fetchSuccess());
      if (isLogin.status) {
        if (isLogin.data.status) {
          localStorage.setItem("token", isLogin.data.access_token);
          localStorage.setItem("auth", isLogin.data.auth);
          localStorage.setItem("username", userName);
          if (rememberMe) {
            localStorage.setItem("rememberMe", isLogin.data.rememberMe);
          } else {
            localStorage.removeItem("rememberMe");
          }
          dispatch(setAuthUser(isLogin.data.access_token));
        } else {
          setInvalid({
            userName: {
              invalid: true,
              msg: "Username หรือ Password ไม่ถูกต้อง",
            },
            password: {
              invalid: true,
              msg: "Username หรือ Password ไม่ถูกต้อง",
            },
          });
        }
      } else {
        dispatch(fetchError("error 400 "));
      }
    }
  };
  const onChange = (e) => {
    if (e.target.name === "username") {
      setInvalid((pre) => ({ ...pre, userName: { msg: "", invalid: !1 } }));
      setUserName(e.target.value);
    } else {
      setPassword(e.target.value);
      setInvalid((pre) => ({ ...pre, password: { msg: "", invalid: !1 } }));
    }
  };
  useEffect(() => {
    (async () => {
      const rememberMe = localStorage.getItem("rememberMe");
      if (!fn.IsNullOrEmpty(rememberMe)) {
        let _rememberme = await postHttp("users/rememberme/" + rememberMe, {});
        try {
          let data = JSON.parse(_rememberme.data.rememberme);
          setUserName(data.userName);
          setPassword(data.password);
        } catch {}
        setrememberMe(true);
      }
    })();
  }, []);
  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CardMedia src={"/images/auth/login-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box
          style={{
            justifyContent: "center",
            display: "flex",
            marginTop: "26px",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/logomain.png?v1.0"}
            style={{
              width: "220px",
              height: "58px",
              objectFit: "scale-down",
              alignItems: "center",
            }}
            alt="logo-air-quality"
            width={"220px"}
            height={"58px"}
          />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box style={{ height: "40", marginBottom: "25px" }}>
            <TextField
              type="text"
              name="username"
              label={"Username"}
              fullWidth
              onChange={onChange}
              value={userName}
              margin="normal"
              variant="outlined"
              className={
                classes.textFieldRoot +
                " " +
                (invalid.userName.invalid ? classes.textFieldRootError : "")
              }
              autoComplete="username"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  document.querySelector("#password").focus();
                }
              }}
            />
            <Box
              style={{
                padding: "0 0 0 10px",
                height: "18px",
                fontSize: "12px",
                color: invalid.userName.invalid ? "red" : "transparent",
                position: "absolute",
              }}
            >
              {invalid.userName.msg}
            </Box>
          </Box>
          <Box style={{ height: "40", marginBottom: "25px" }}>
            <TextField
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              label={"Password"}
              fullWidth
              onChange={onChange}
              value={password}
              margin="normal"
              variant="outlined"
              className={
                classes.textFieldRoot +
                " " +
                (invalid.password.invalid ? classes.textFieldRootError : "")
              }
              autoComplete="current-password"
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  onSubmit();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setshowPassword(!showPassword);
                      }}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      ) : (
                        <Visibility
                          style={{ color: "c9c9c9", width: "15px" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              style={{
                padding: "0 0 0 10px",
                height: "18px",
                fontSize: "12px",
                color: invalid.password.invalid ? "red" : "transparent",
                position: "absolute",
              }}
            >
              {invalid.password.msg}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: "35px" }}
          >
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={
                <Checkbox
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={() => {
                    setrememberMe(!rememberMe);
                  }}
                />
              }
              label="Remember me"
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Button
              onClick={onSubmit}
              variant="contained"
              color="primary"
              style={{ width: "100%" }}
            >
              Login
            </Button>
          </Box>
        </form>

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
